const GET_TOKEN = 'GET_TOKEN'
const GET_LANGUAGES = 'GET_LANGUAGES'
const GET_CHAPTERS = 'GET_CHAPTERS'
const SHOW_LOADER = 'SHOW_LOADER'
const HIDE_LOADER = 'HIDE_LOADER'
const REMOVE_DATA = 'REMOVE_DATA'
//const DEV_API_BASE_URL = 'http://15.207.203.61:8199'
const CLIENT_API_BASE_URL = 'https://admin.hepbstory.menzies.edu.au'
const API_BASE_URL = CLIENT_API_BASE_URL

export { GET_TOKEN, GET_LANGUAGES, GET_CHAPTERS, SHOW_LOADER, HIDE_LOADER, REMOVE_DATA, API_BASE_URL }