import React, { PureComponent } from "react";
import Page404 from "../../assets/images/404.png"
import { withRouter } from "react-router-dom";

class NoMatch extends PureComponent {

    handleRedirectToHome = () => {
        this.props.history.push('/');
    }

    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="container">
                            <div className="content">
                                <div className="section d-flex flex-column flex-fill justify-content-center">
                                    <div className="section-body">
                                        <div className="page-not-found-container">
                                            <div className="mb-4">
                                                <img src={Page404} width="140" className="mx-auto" alt="404" />
                                            </div>
                                            <h1 className="mb-4"> Sorry, the page you requested was<strong className="text-primary-theme"> Not Found. </strong></h1>
                                            <button type="button" onClick={this.handleRedirectToHome} className="btn btn-success-theme w-auto">Go to Home</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(NoMatch);