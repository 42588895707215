import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import MenziesLogo from "../../assets/images/menzies.svg";
// import Chapter from "../../assets/images/chapter.svg";
// import Women from "../../assets/images/women.svg";
import { connect } from 'react-redux';
import { fetchToken, fetchLanguage } from "../../redux";
import LanguageDefault from "../../assets/images/language-default.svg";
import ChapterNew from "../../assets/images/chapter-new.svg";
import WomenBusiness from "../../assets/images/women-business.svg";
import HomePersonNew from "../../assets/images/home-person-new.svg";

class Home extends PureComponent {

  constructor(props) {
    super(props)

    this.popupRef = React.createRef();
    // this.state = this.props.location.state || { popup: true };

    const locationState = this.props.location.state || {}
    const { lanid = 1, popup = true, lanname = "English" } = locationState

    this.state = {
      lanid: lanid,
      popup: popup,
      language: {},
      lanname: lanname
    }

    this.handleLoad = this.handleLoad.bind(this);
    this.props.history.replace(this.props.location.pathname, this.state); // Update state of current entry in history stack.
  }

  handleLoad() {
    this.setState(prevStates => ({
      ...prevStates,
      popup: true
    }));
  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad);
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad)
  }

  componentWillMount() {
    // this.setState({
    //   popup: true
    // });
    // console.log("componentWillMount this.state", this.state)
    // console.log("componentWillMount this.props", this.props)

    // let myPromise = new Promise((resolve, reject) => {
    //   //console.log("fetchToken");
    //   this.props.fetchToken()
    //   resolve("success")
    // })

    // myPromise.then((value) => {
    //   //console.log("fetchLanguage");
    //   this.props.fetchLanguage(this.props.tokenData.authToken.token)
    // },
    //   (error) => {
    //     //console.log(error)
    //   }
    // )

    this.props.fetchToken()
    // this.props.fetchLanguage(this.props.tokenData.authToken.token)

    // let languageVar = this.props.languageData != null
    //   && this.props.languageData.languages != null
    //   && this.props.languageData.languages.list != null
    //   && this.props.languageData.languages.list.find(language => language.id === this.state.lanid);
    //   //console.log("languageVar",languageVar);
    // this.setState(prevState => ({
    //   ...prevState,
    //   language: languageVar
    // }));

  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    //console.log("componentDidUpdate")
    // console.log("componentDidUpdate this.state", this.state)
    // console.log("componentDidUpdate this.props", this.props)
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      //console.log("inside")
      //this.setState(this.props.location.state);
      this.setState(prevStates => ({
        ...prevStates,
        popup: this.props.location.state.popup,
        lanid: this.props.location.state.lanid,
        lanname: this.props.location.state.lanname,
      }));
      // console.log("componentDidUpdate this.state", this.state)
      // console.log("componentDidUpdate this.props", this.props)
    }

    // if (this.props.languageData !== prevProps.languageData) {

    //   let languageVar = this.props.languageData != null
    //     && this.props.languageData.languages != null
    //     && this.props.languageData.languages.list != null
    //     && this.props.languageData.languages.list.find(language => language.id === this.state.lanid)
    //     //console.log("languageVar",languageVar);
    //   this.setState(prevState => ({
    //     ...prevState,
    //     language: languageVar
    //   }));

    // }
  }

  handleRedirectToLanguage = (chapterName) => {
    // this.props.history.push("/language");
    this.props.history.push({
      pathname: '/language',
      state: {
        chapterName: chapterName,
        lanid: this.state.lanid,
        lanname: this.state.lanname
      },
    });
  };

  handleRedirectToChapter = (id) => {
    this.props.history.push({
      pathname: '/chapter',
      state: {
        id: id
      },
    });
  };

  handleRedirectToSubChapter = (id) => {
    //this.props.history.push('/story/introduction');
    this.props.history.push({
      pathname: '/story/introduction',
      state: {
        id: id
      },
    });
  };

  closePopup = (hide) => {
    this.popupRef.current.style.display = hide
    this.setState(prevStates => ({
      ...prevStates,
      popup: false
    }));
    // console.log("closePopup this.state", this.state)
    // console.log("closePopup this.props", this.props)
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="main-container">
            <div className="container">
              <div className="content">
                <div className="section home-section d-flex flex-column flex-fill">
                  <div className="section-body flex-fill">
                    {/* <div className="d-flex align-items-center globe-tag online">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#b0b0b0" class="bi bi-globe mr-1" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                      </svg>
                      Online
                    </div> */}
                    <div className="form-row">
                      <div className="col-lg-8">
                        <div className="main-logo mt-4 mt-lg-5 pt-3">
                          <img src={Logo} alt="Hep B Story Logo" height="120" />
                        </div>
                        <div className="btn-container main mx-auto my-4 my-lg-5">
                          <div className="form-row row-cols-1 row-cols-md-2 justify-content-center">
                            <div className="col mb-3">
                              <button type="button" onClick={() => this.handleRedirectToSubChapter(this.state.lanid)} className="btn btn-lg btn-success-theme d-flex align-items-center">
                                <img src={LanguageDefault} className="mr-3" width="45" alt="English" /> 
                                { this.state.lanname }
                              </button>
                            </div>
                            <div className="col mb-3">
                              <button type="button" onClick={() => this.handleRedirectToLanguage("Chapter")} className="btn btn-lg btn-success-theme d-flex align-items-center">
                                <img src={LanguageDefault} className="mr-3" width="45" alt="More Languages" /> More <br /> Languages
                              </button>
                            </div>
                            <div className="col mb-3">
                              <button type="button" onClick={() => this.handleRedirectToChapter(this.state.lanid)} className="btn btn-lg btn-success-theme d-flex align-items-center">
                                <img src={ChapterNew} className="mr-3" width="45" alt="Chapter" /> Select Chapter
                              </button>
                            </div>
                            {/* <div className="col mb-3">
                              <button type="button" onClick={() => this.handleRedirectToLanguage("Women")} className="btn btn-lg btn-success-theme d-flex align-items-center">
                                <img src={WomenBusiness} className="mr-3" width="45" alt="Women" /> Women's Business
                              </button>
                            </div> */}
                          </div>
                        </div>
                        <div className="menzies-logo">
                          <img src={MenziesLogo} alt="Menzies Logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home-person-image">
                    <img src={HomePersonNew} alt="Home Person" />
                  </div>
                  {
                    this.state.popup &&
                    <div className="fade modal show d-block warning-modal">
                      <div className="modal-dialog modal-dialog-centered">
                        <div ref={this.popupRef} className="modal-content text-center border-0" style={{ display: "inline" }}>
                          <div className="modal-header justify-content-center py-4">
                            <h5 className="h4 text-uppercase font-weight-normal m-0">Warning</h5>
                            <button type="button" onClick={() => this.closePopup("none")} className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body p-4">
                            <p>This story may contain voices of Aboriginal people who have passed away.</p>
                            <p>If you are worried about anything you hear in the 'Hep B Story' go to the clinic to see the doctor.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tokenData: state.token,
    languageData: state.language,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchToken: () => {
      dispatch(fetchToken())
    },
    fetchLanguage: (authToken) => {
      dispatch(fetchLanguage(authToken))
    }
  }
}

// export default withRouter(Home);

const HomeForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Home));

export default HomeForm;