import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import Close from "../../assets/images/close.svg";
import { connect } from 'react-redux';
import { fetchChapter, fetchToken, fetchLanguage, showLoader, hideLoader, removeChapterData } from "../../redux"
import Loader from "../loader/loader"

class Chapter extends PureComponent {

  constructor(props) {
    super(props);
    //this.state = this.props.location.state || { id: "1" }; // In the ||, set default state.

    const locationState = this.props.location.state || {}
    const { id = "1", lanname = "English" } = locationState

    this.state = {
      id: id,
      lanname: lanname,
      loading: true
    }

    this.props.history.replace(this.props.location.pathname, this.state); // Update state of current entry in history stack.
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.chapterData != null
      && nextProps.chapterData.chapters != null
      && nextProps.chapterData.chapters.list != null
      && nextProps.chapterData.chapters.list.length > 0) {
      this.setState(prevState => ({
        ...prevState,
        loading: false
      }));
    }
  }

  componentWillMount() {
    this.props.showLoader()
    this.setState(prevState => ({
      ...prevState,
      loading: true
    }));
    // const auth = localStorage.getItem('auth')
    //localStorage.setItem('language_id', this.props.location.state)
    // var array = this.props.location.pathname.split("/");
    //console.log("language id 2", localStorage.getItem('language_id'))
    this.props.tokenData == null && this.props.fetchToken()
    this.props.fetchLanguage(this.props.tokenData.authToken.token)
    this.props.removeChapterData()
    this.props.fetchChapter(this.state.id, this.props.tokenData.authToken.token)

    let languageVar = this.props.languageData != null
      && this.props.languageData.languages != null
      && this.props.languageData.languages.list != null
      && this.props.languageData.languages.list.find(language => language.id === this.state.id);
    this.setState(prevState => ({
      ...prevState,
      lanname: languageVar.language
    }));

    // let chapterVar = this.props.chapterData != null
    //   && this.props.chapterData.chapters != null
    //   && this.props.chapterData.chapters.list != null
    //   && this.props.chapterData.chapters.list;
    // this.setState(prevState => ({
    //   ...prevState,
    //   chapters: chapterVar
    // }));

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log("componentDidUpdate")
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      //this.setState(this.props.location.state);
      this.setState(prevState => ({
        ...prevState,
        lanname: this.props.location.state.lanname,
        id: this.props.location.state.id
      }));
    }

    if (this.props.languageData !== prevProps.languageData) {

      let languageVar = this.props.languageData != null
        && this.props.languageData.languages != null
        && this.props.languageData.languages.list != null
        && this.props.languageData.languages.list.find(language => language.id === this.state.id);
      this.setState(prevState => ({
        ...prevState,
        lanname: languageVar.language
      }));

    }

    // if (this.props.chapterData !== prevProps.chapterData) {

    //   let chapterVar = this.props.chapterData != null
    //     && this.props.chapterData.chapters != null
    //     && this.props.chapterData.chapters.list != null
    //     && this.props.chapterData.chapters.list;
    //   this.setState(prevState => ({
    //     ...prevState,
    //     chapters: chapterVar
    //   }));

    // }

  }

  handleRedirectToLanguage = () => {
    //this.props.history.push("/language");
    this.props.history.push({
      pathname: '/language',
      state: {
        lanid: this.state.id,
        lanname: this.state.lanname
      },
    });
  };

  handleRedirectToSubChapter = (chapter, subchapter) => {
    //this.props.history.push(`/${chapter.identifier}/${subchapter.identifier}`);
    this.props.history.push({
      pathname: `/${chapter.identifier}/${subchapter.identifier}`,
      state: {
        id: this.state.id
      },
    });
  };

  render() {
    return (
      this.state.loading
        //this.state.chapters.length <= 0
        //this.props.chapterData.length <= 0
        // this.props.chapterData != null
        // && this.props.chapterData.chapters != null
        // && this.props.chapterData.chapters.list != null
        // && this.props.chapterData.chapters.list.length <= 0
        ?
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content">
                  <div className="section chapter-section d-flex flex-column flex-fill">
                    <div className="section-body flex-fill">
                      <Loader />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        :
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content">
                  <div className="section chapter-section d-flex flex-column flex-fill">
                    <div className="section-header">
                      <h1 className="m-0"><span className="font-size-45 font-weight-bold text-primary-theme">Chapter</span> Select</h1>
                      {/* <Link to="/language" className="close-icon">
                      <img src={Close} width="25" alt="Close" />
                    </Link> */}
                      <button type="button" onClick={this.handleRedirectToLanguage} className="close-icon btn-sound">
                        <img src={Close} width="25" alt="Close" />
                      </button>
                    </div>
                    <div className="section-body flex-fill">
                      <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4">


                        {

                          // <h2>{this.props.tokenData.token.token}</h2>

                          // this.state.chapters != null
                          // && this.state.chapters.length
                          // && this.state.chapters.map(chapter => {

                            this.props.chapterData != null
                            && this.props.chapterData.chapters != null
                            && this.props.chapterData.chapters.list != null
                            && this.props.chapterData.chapters.list.map(chapter => {

                            if (chapter.chapter_name) {
                              return (
                                <div className="col mb-2" key={chapter.chapter_id}>
                                  <button type="button" className="btn btn-lg btn-success-theme mb-3 cursor-default">{chapter.chapter_name}</button>
                                  {

                                    chapter.sub_chapters.map(subchapter => {
                                      if (subchapter.sub_chapter_name) {
                                        return (
                                          <div key={subchapter.sub_chapter_id}>
                                            <button type="button" onClick={() => this.handleRedirectToSubChapter(chapter, subchapter)} className="btn btn-secondary-theme mb-3">{subchapter.sub_chapter_name}</button>
                                          </div>
                                        )
                                      }
                                    })
                                  }
                                </div>
                              )
                            }
                          })
                        }


                        {/* <div className="col mb-2">
                        <button type="button" className="btn btn-lg btn-success-theme mb-3 cursor-default">The Story</button>
                        <button type="button" onClick={this.handleRedirectToIntroduction} className="btn btn-secondary-theme mb-3">Introduction</button>
                        <button type="button" onClick={this.handleRedirectToCommoninNT} className="btn btn-secondary-theme mb-3">Common in NT</button>
                        <button type="button" onClick={this.handleRedirectToFishing} className="btn btn-secondary-theme mb-3">Fishing</button>
                        <button type="button" onClick={this.handleRedirectToOtherWaysToGetHepB} className="btn btn-secondary-theme mb-3">Other ways to get Hep B</button>
                      </div>
                      <div className="col mb-2">
                        <button type="button" className="btn btn-lg btn-success-theme mb-3 cursor-default">What your Liver Does</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Liver Function</button>
                      </div>
                      <div className="col mb-2">
                        <button type="button" className="btn btn-lg btn-success-theme mb-3 cursor-default">Only your blood can tell the story</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Blood Test</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Regular Checks</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Sleeping Disease</button>
                      </div>
                      <div className="col mb-2">
                        <button type="button" className="btn btn-lg btn-success-theme mb-3 cursor-default">You can be protected</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Immunisation</button>
                      </div>
                      <div className="col mb-2">
                        <button type="button" className="btn btn-lg btn-success-theme mb-3 cursor-default">What happens over time</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">School</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Adults</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Alcohol</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Live Cancer is more common</button>
                      </div>
                      <div className="col mb-2">
                        <button type="button" className="btn btn-lg btn-success-theme mb-3 cursor-default">Treatment</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Not everyone needs treatment</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Damaged Liver</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">How to take your tablets</button>
                        <button type="button" className="btn btn-secondary-theme mb-3">Important messages</button>
                      </div> */}
                        {/* <div className="col mb-2">
                        <button type="button" className="btn btn-lg btn-success-theme mb-3 cursor-default">Women's Business</button>
                        <button type="button" onClick={this.handleRedirectToPregnantWomen} className="btn btn-secondary-theme mb-3">Pregnant Women</button>
                        <button type="button" onClick={this.handleRedirectToTreatmentPaths} className="btn btn-secondary-theme mb-3">Treatment Paths</button>
                        <button type="button" onClick={this.handleRedirectToTreatmentForBaby} className="btn btn-secondary-theme mb-3">Treatment for baby</button>
                      </div> */}
                      </div>
                    </div>
                    <div className="text-center text-md-right mt-n5 pb-3 pr-3">
                      <button type="button" onClick={this.handleRedirectToLanguage} className="btn btn-lg btn-primary-theme back-btn">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("chapter mapStateToProps")
  return {
    tokenData: state.token,
    chapterData: state.chapter,
    languageData: state.language,
    loading: state.chapter.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  //console.log("chapter mapDispatchToProps")
  return {
    fetchChapter: (languageid, authToken) => {
      //console.log("chapter mapDispatchToProps 2", authToken)
      dispatch(fetchChapter(languageid, authToken))
    },
    fetchLanguage: (authToken) => {
      dispatch(fetchLanguage(authToken))
    },
    fetchToken: () => {
      dispatch(fetchToken())
    },
    showLoader: () => {
      dispatch(showLoader())
    },
    hideLoader: () => {
      dispatch(hideLoader())
    },
    removeChapterData: () => {
      dispatch(removeChapterData())
    }
  }
}

// export default withRouter(Chapter);

const ChapterForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Chapter));

export default ChapterForm;

// export default compose(
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps)
// )(Chapter);
