import { GET_CHAPTERS, SHOW_LOADER, HIDE_LOADER, REMOVE_DATA } from "../constants/types"

const initialState = {
    loading: false,
    chapters: [],
    error: ''
}

const chapterReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_CHAPTERS:
            return {
                ...state,
                chapters: { ...action.payload }
            }
        
            
        case REMOVE_DATA:
            return {
                ...state,
                chapters: { ...action.payload }
            }
        
        case SHOW_LOADER:
            return {
                ...state,
                loading: true
            }
        
        case HIDE_LOADER:
            return {
                ...state,
                loading: false
            }

        default: return state
    }
}

export default chapterReducer