import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

export class Loader extends Component {
  render() {

    const { loading } = this.props;

    return (
      <React.Fragment>
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    loading: state.chapter.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //   showLoader: () => {
    //     dispatch(showLoader())
    //   }
  }
}

const LoaderForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Loader));

export default LoaderForm;
