import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { routes } from "./routeConstants";
//import { NoMatch } from "../pages";

export default function Routes() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        {routes.map((item, index) => (
          <Route exact key={index} {...item} />
        ))}
        {/* <Route exact render={() => <Redirect to="/" />} /> */}
        {/* <Route path="*" component={NoMatch} /> */}
      </Switch>
    </Router>
  );
}
