import { Language, Home, Chapter, Introduction, CommoninNT, Fishing, OtherWaysToGetHepB, PregnantWomen, TreatmentPaths, TreatmentForBaby, Acknowledgements, LiverFunction, BloodTest, RegularChecks, SleepingDisease, Immunisation, School, Adults, Alcohol, LiverCancerIsMoreCommon, NotEveryoneNeedsTreatment, DamagedLiver, HowToTakeYourTablets, TreatmentImportance, GameLevel, GameSuccess, GameFailure, GameDay1, GameDay2, GameDay3, GameDay4, GameDay5, GameDay6, GameDay7, GameDay8, NoMatch } from '../pages';

export const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/language",
    component: Language,
  },
  {
    path: "/chapter",
    component: Chapter,
  },
  {
    path: "/story/introduction",
    component: Introduction,
  },
  {
    path: "/story/common_in_nt",
    component: CommoninNT,
  },
  {
    path: "/story/fishing",
    component: Fishing,
  },
  {
    path: "/story/other_ways_to_get_hep_b",
    component: OtherWaysToGetHepB,
  },
  {
    path: "/acknowledgements",
    component: Acknowledgements,
  },
  {
    path: "/womens_business/pregnant_women",
    component: PregnantWomen,
  },
  {
    path: "/womens_business/treatment_paths",
    component: TreatmentPaths,
  },
  {
    path: "/womens_business/treatment_for_baby",
    component: TreatmentForBaby,
  },
  {
    path: "/what_your_liver_does/liver_function",
    component: LiverFunction,
  },
  {
    path: "/only_your_blood_can_tell_the_story/blood_test",
    component: BloodTest,
  },
  {
    path: "/only_your_blood_can_tell_the_story/regular_checks",
    component: RegularChecks,
  },
  {
    path: "/only_your_blood_can_tell_the_story/sleeping_disease",
    component: SleepingDisease,
  },
  {
    path: "/you_can_be_protected/immunisation",
    component: Immunisation,
  },
  {
    path: "/what_happens_over_time/school",
    component: School,
  },
  {
    path: "/what_happens_over_time/adults",
    component: Adults,
  },
  {
    path: "/what_happens_over_time/alcohol",
    component: Alcohol,
  },
  {
    path: "/what_happens_over_time/liver_cancer_is_more_common",
    component: LiverCancerIsMoreCommon,
  },
  {
    path: "/treatment/not_everyone_needs_treatment",
    component: NotEveryoneNeedsTreatment,
  },
  {
    path: "/treatment/damaged_liver",
    component: DamagedLiver,
  },
  {
    path: "/treatment/how_to_take_your_tablets",
    component: HowToTakeYourTablets,
  },
  {
    path: "/treatment/game_level",
    component: GameLevel,
  },
  {
    path: "/treatment/game_day_1",
    component: GameDay1,
  },
  {
    path: "/treatment/game_day_2",
    component: GameDay2,
  },
  {
    path: "/treatment/game_day_3",
    component: GameDay3,
  },
  {
    path: "/treatment/game_day_4",
    component: GameDay4,
  },
  {
    path: "/treatment/game_day_5",
    component: GameDay5,
  },
  {
    path: "/treatment/game_day_6",
    component: GameDay6,
  },
  {
    path: "/treatment/game_day_7",
    component: GameDay7,
  },
  {
    path: "/treatment/game_day_8",
    component: GameDay8,
  },
  {
    path: "/treatment/game_success",
    component: GameSuccess,
  },
  {
    path: "/treatment/game_failure",
    component: GameFailure,
  },
  {
    path: "/treatment/treatment_importance",
    component: TreatmentImportance,
  },
  {
    path: "*",
    component: NoMatch,
  }
];
