import { GET_LANGUAGES, SHOW_LOADER, HIDE_LOADER, API_BASE_URL } from "../constants/types"
import axios from "axios"

const getLanguages = (languages) => {
    return {
        type: GET_LANGUAGES,
        payload: languages
    }
}

const displayLoader = () => {
    return {
        type: SHOW_LOADER
    }
}

export const showLoaderLanguage = () => {
    return (dispatch) => {
        dispatch(displayLoader())
    }
}

const removeLoader = () => {
    return {
        type: HIDE_LOADER
    }
}

export const hideLoaderLanguage = () => {
    return (dispatch) => {
        dispatch(removeLoader())
    }
}

export const fetchLanguage = (authToken) => {

    //console.log("fetchLanguage action", authToken)

    return (dispatch) => {

        axios({
            method: "post",
            url: `${API_BASE_URL}/api/v1/common/get-language`,
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${authToken}`
            }
        })
            .then(res => {
                dispatch(getLanguages(res.data.data))
                dispatch(hideLoaderLanguage())
                //console.log(res.data)
                //console.log(res.data.data)
                //console.log(res.data.data.list)
            })
            .catch(err => {
                //console.log(err)
            })
    }
}