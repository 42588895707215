import { combineReducers } from "redux";
import tokenReducer from './reducers/tokenReducer';
import languageReducer from './reducers/languageReducer';
import chapterReducer from "./reducers/chapterReducer";

const rootReducer = combineReducers({
    token: tokenReducer,
    language: languageReducer,
    chapter: chapterReducer
})

export default rootReducer