// import logo from './logo.svg';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.scss';
import './Responsive.scss';
//import { Language, Home, Chapter, Introduction, CommoninNT, Fishing, OtherWaysToGetHepB, PregnantWomen, TreatmentPaths, TreatmentForBaby, Acknowledgements, LiverFunction, BloodTest, RegularChecks, SleepingDisease, Immunisation, School, Adults, Alcohol, LiverCancerIsMoreCommon, NotEveryoneNeedsTreatment, DamagedLiver, HowToTakeYourTablets, TreatmentImportance, GameLevel, GameSuccess, GameFailure, GameDay1, GameDay2, GameDay3, GameDay4, GameDay5, GameDay6, GameDay7, GameDay8, NoMatch } from './pages';
import PersistedStore from './redux/store'
import { Provider } from 'react-redux'
import Routes from "./routes";

function App() {
  return (
    <Provider store={PersistedStore.getDefaultStore().store}>
      <div className="App">
        <Routes />
        {/* <BrowserRouter>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/language' exact component={Language} />
            <Route path='/chapter' exact component={Chapter} />
            <Route path='/story/introduction' exact component={Introduction} />
            <Route path='/story/common_in_nt' exact component={CommoninNT} />
            <Route path='/story/fishing' exact component={Fishing} />
            <Route path='/story/other_ways_to_get_hep_b' exact component={OtherWaysToGetHepB} />
            <Route path='/acknowledgements' exact component={Acknowledgements} />
            <Route path='/womens_business/pregnant_women' exact component={PregnantWomen} />
            <Route path='/womens_business/treatment_paths' exact component={TreatmentPaths} />
            <Route path='/womens_business/treatment_for_baby' exact component={TreatmentForBaby} />
            <Route path='/what_your_liver_does/liver_function' exact component={LiverFunction} />
            <Route path='/only_your_blood_can_tell_the_story/blood_test' exact component={BloodTest} />
            <Route path='/only_your_blood_can_tell_the_story/regular_checks' exact component={RegularChecks} />
            <Route path='/only_your_blood_can_tell_the_story/sleeping_disease' exact component={SleepingDisease} />
            <Route path='/you_can_be_protected/immunisation' exact component={Immunisation} />
            <Route path='/what_happens_over_time/school' exact component={School} />
            <Route path='/what_happens_over_time/adults' exact component={Adults} />
            <Route path='/what_happens_over_time/alcohol' exact component={Alcohol} />
            <Route path='/what_happens_over_time/liver_cancer_is_more_common' exact component={LiverCancerIsMoreCommon} />
            <Route path='/treatment/not_everyone_needs_treatment' exact component={NotEveryoneNeedsTreatment} />
            <Route path='/treatment/damaged_liver' exact component={DamagedLiver} />
            <Route path='/treatment/how_to_take_your_tablets' exact component={HowToTakeYourTablets} />
            <Route path='/treatment/game_level' exact component={GameLevel} />
            <Route path='/treatment/game_day_1' exact component={GameDay1} />
            <Route path='/treatment/game_day_2' exact component={GameDay2} />
            <Route path='/treatment/game_day_3' exact component={GameDay3} />
            <Route path='/treatment/game_day_4' exact component={GameDay4} />
            <Route path='/treatment/game_day_5' exact component={GameDay5} />
            <Route path='/treatment/game_day_6' exact component={GameDay6} />
            <Route path='/treatment/game_day_7' exact component={GameDay7} />
            <Route path='/treatment/game_day_8' exact component={GameDay8} />
            <Route path='/treatment/game_success' exact component={GameSuccess} />
            <Route path='/treatment/game_failure' exact component={GameFailure} />
            <Route path='/treatment/treatment_importance' exact component={TreatmentImportance} />
            <Route path="*" component={NoMatch} />
          </Switch>
        </BrowserRouter> */}
      </div>
    </Provider>
  );
}

export default App;
