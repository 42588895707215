import React, { PureComponent } from "react";
import { withRouter, Link } from "react-router-dom";
import SoundOn from "../../../assets/images/sound-on.png";
import ArrowBack from "../../../assets/images/arrow-back.svg";
import ArrowNext from "../../../assets/images/arrow-next.svg";
//import ReactHtmlParser from "react-html-parser";
import { connect } from 'react-redux';
import Pause from "../../../assets/images/pause.png";
// import IntrodctionVideo from "../../../assets/videos/en-introduction.mp4"
import { fetchChapter, fetchToken, showLoader, hideLoader } from "../../../redux"
import Loader from "../../loader/loader";
import ReactHtmlParser from "react-html-parser";

class Introduction extends PureComponent {

  constructor(props) {
    super(props)

    this.vidRef = React.createRef();
    this.imgRef = React.createRef();
    this.audioRef = React.createRef();

    const locationState = this.props.location.state || {}
    const { id = 1 } = locationState

    this.state = {
      audio: null,
      isPlaying: false,
      chapter: {},
      subChapter: {},
      id: id,
      playEnd: false,
      loading: false
    }

    this.props.history.replace(this.props.location.pathname, this.state);

  }

  end() {
    this.setState(prevState => ({
      ...prevState,
      playEnd: !prevState.playEnd,
      isPlaying: !prevState.isPlaying,
    }));
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]) {
      this.vidRef.current.currentTime = 0;
    }
  }

  componentDidUpdate(prevProps, prevStates, snapshot) {

    if (this.props.location !== prevProps.location) {
      this.setState(prevStates => ({
        ...prevStates,
        id: this.props.location.state.id
      }));
    }

    if (this.props.chapterData !== prevProps.chapterData) {
      // var array = this.props.match.path.split("/");
      // let chapterVar = this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
      // this.setState(prevState => ({
      //   ...prevState,
      //   chapter: chapterVar,
      //   subChapter: chapterVar.sub_chapters.find(subchapter => subchapter.identifier === array[array.length - 1])
      // }));

      var array = this.props.match.path.split("/");
      let chapterVar = this.props.chapterData != null 
      && this.props.chapterData.chapters != null
      && this.props.chapterData.chapters.list != null
      && this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
      this.setState(prevState => ({
        ...prevState,
        chapter: chapterVar,
        subChapter: chapterVar != null 
        && chapterVar.sub_chapters != null
        && chapterVar.sub_chapters.find(subchapter => subchapter.identifier === array[array.length - 1])
      }));

    }
  }

  playPause = () => {

    let isPlaying = this.state.isPlaying;
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]) {
      if (isPlaying) {
        this.vidRef.current.pause();
      } else {
        this.vidRef.current.play();
      }
    } else {
      if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
        if (isPlaying) {
          this.audioRef.current.pause();
        } else {
          this.audioRef.current.play();
        }
      }
    }

    this.setState(prevState => ({
      ...prevState,
      isPlaying: !prevState.isPlaying
    }));
  };

  handleRedirect = (pathName) => {
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]) {
      this.vidRef.current.pause();
    } else if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      this.audioRef.current.pause();
    }
    this.setState(prevState => ({
      ...prevState,
      isPlaying: !prevState.isPlaying
    }));
    this.props.history.push({
      pathname: pathName,
      state: {
        id: this.state.id
      },
    });
  }

  componentWillMount() {

    this.props.showLoader()

    this.setState(prevState => ({
      ...prevState,
      loading: this.props.loading
    }));

    this.props.tokenData == null && this.props.fetchToken()
    this.props.fetchChapter(this.state.id, this.props.tokenData.authToken.token)

    var array = this.props.match.path.split("/");
    let chapterVar = this.props.chapterData != null 
    && this.props.chapterData.chapters != null
    && this.props.chapterData.chapters.list != null
    && this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
    this.setState(prevState => ({
      ...prevState,
      chapter: chapterVar,
      subChapter: chapterVar != null 
      && chapterVar.sub_chapters != null
      && chapterVar.sub_chapters.find(subchapter => subchapter.identifier === array[array.length - 1])
    }));
  }

  componentDidMount() {

    // setTimeout(() => {
    //   this.props.hideLoader()
    // }, 500);

    this.setState(prevState => ({
      ...prevState,
      loading: this.props.loading
    }));

    this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0] && this.setState(prevState => ({
      ...prevState,
      audio: new Audio(this.state.subChapter.data[0].audio[0])
    }));

    var promise

    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]) {
      if (this.vidRef.current != null)
        promise = this.vidRef.current.play();
    } else if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      if (this.audioRef.current != null)
        promise = this.audioRef.current.play();
    }

    if (promise !== undefined) {
      promise.then(_ => {
        this.setState(prevState => ({
          ...prevState,
          isPlaying: true
        }));
      }).catch(error => {
        this.setState(prevState => ({
          ...prevState,
          isPlaying: false
        }));
      });
    }

    //window.addEventListener("focus", this.onFocus)
    window.addEventListener('blur', this.onBlur);
  }

  onFocus = () => {
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]) {
      this.vidRef.current.play();
    } else if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      this.audioRef.current.play();
    }
    this.setState(prevState => ({
      ...prevState,
      isPlaying: true
    }));
  }

  onBlur = () => {
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]) {
      if (this.vidRef.current != null)
        this.vidRef.current.pause();
    } else if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      if (this.audioRef.current != null)
        this.audioRef.current.pause();
    }
    this.setState(prevState => ({
      ...prevState,
      isPlaying: false
    }));
  }

  componentWillUnmount() {
    //window.removeEventListener("focus", this.onFocus)
    window.removeEventListener('blur', this.onBlur);
  }

  start() {
    //console.log("started")
    var promise

    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]) {
      if (this.vidRef.current != null)
        promise = this.vidRef.current.play();
    } else if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      if (this.audioRef.current != null)
        promise = this.audioRef.current.play();
    }

    if (promise !== undefined) {
      promise.then(_ => {
        this.setState(prevState => ({
          ...prevState,
          isPlaying: true
        }));
      }).catch(error => {
        this.setState(prevState => ({
          ...prevState,
          isPlaying: false
        }));
      });
    }
  }

  render() {

    // if(this.props.loading) {
    //   return(
    //     <Loader />
    //   )
    // } else {

    return (

      this.props.loading
        ?
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content story">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-body flex-fill">
                    <Loader />
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        :
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content story">
                  <div className="section d-flex flex-column flex-fill">
                    <div className="section-header">
                      <h1 className="m-0">
                        {
                          this.state.id === 1 ?
                            <><span className="font-size-45 font-weight-bold text-primary-theme">{this.state.chapter.chapter_name} </span>
                              {this.state.subChapter.sub_chapter_name}</>
                            :
                            <span className="font-size-45 font-weight-bold text-primary-theme">{this.state.subChapter.sub_chapter_name}</span>
                        }
                      </h1>

                      <button onClick={this.playPause} className="sound-icon btn-sound">
                        {
                          this.state.isPlaying
                            ? <img src={Pause} alt="Pause" />
                            : <img src={SoundOn} alt="Sound" />
                        }
                      </button>

                    </div>
                    <div className="section-body d-md-flex flex-md-column flex-fill justify-content-around">
                      <div className="section-image video-player mb-3 mb-lg-0">

                        {
                          this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]
                            ? <video ref={this.vidRef} className="vid-img-size" onEnded={() => this.end()} src={this.state.subChapter.data[0].video[0]} onLoadStart={() => this.start()} autoPlay />
                            : this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0] &&
                            <div>
                              <audio ref={this.audioRef} onEnded={() => this.end()} src={this.state.subChapter.data[0].audio[0]} onLoadStart={() => this.start()} autoPlay />
                              {this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].image && this.state.subChapter.data[0].image[0] && <img ref={this.imgRef} src={this.state.subChapter.data[0].image[0]} alt={this.state.subChapter.sub_chapter_name} />}
                            </div>
                        }

                        {/* <img ref="imgRef" src={this.state.subChapter.image} alt="Introduction" />
                        <video src={this.state.subChapter.video} hidden></video>
                        <video style={{display: "none"}} ref="vidRef" src={IntrodctionVideo}></video> */}

                      </div>
                      <div className="text-left">
                        {
                          this.state.subChapter.data
                          && this.state.subChapter.data[0]
                          && this.state.subChapter.data[0].content.split("\n").map((item, idx) => {
                            return (
                              <span key={idx}>
                                {item}
                                <br />
                              </span>
                            )
                          })
                        }
                        {/* {ReactHtmlParser(this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].content)} */}
                        <br />
                        <a href="https://bmcpublichealth.biomedcentral.com/articles/10.1186/1471-2458-14-1233" target="_blank" className="link" >Davies et al. (2014),</a>
                        <a href="https://www.researchgate.net/publication/278042538_Development_of_a_Culturally_Appropriate_Bilingual_Electronic_App_About_Hepatitis_B_for_Indigenous_Australians_Towards_Shared_Understandings" target="_blank" className="link"> Davies et al. (2015)</a>
                      </div>
                    </div>
                    <div className="section-footer">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <button onClick={() => this.handleRedirect("/chapter")} className="btn-sound">
                          <img src={ArrowBack} alt="Arrow Back" />
                        </button>
                        <button onClick={() => this.handleRedirect("/chapter")} className="btn-sound">
                          <h3 className="text-primary-theme m-0">Select Chapter</h3>
                        </button>
                        <button onClick={() => this.handleRedirect("/story/common_in_nt")} className="btn-sound">
                          <img src={ArrowNext} alt="Arrow Next" />
                        </button>
                      </div>
                    </div>
                    {/* Warning Modal Start */}
                    {/* {
                      this.state.popup &&
                      <div className="fade modal show d-block warning-modal">
                        <div className="modal-dialog modal-dialog-centered">
                          <div ref={this.popupRef} className="modal-content text-center border-0" style={{ display: "inline" }}>
                            <div className="modal-header justify-content-center py-4">
                              <h5 className="h4 text-uppercase font-weight-normal m-0">Warning</h5>
                              <button type="button" onClick={() => this.closePopup("none")} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body p-4">
                              <p>This story may contain voices of Aboriginal people who have passed away.</p>
                              <p>If you are worried about anything you hear in the 'Hep B Story' go to the clinic to see the doctor.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    } */}
                    {/* Warning Modal End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tokenData: state.token,
    chapterData: state.chapter,
    loading: state.chapter.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChapter: (languageid, authToken) => {
      dispatch(fetchChapter(languageid, authToken))
    },
    fetchToken: () => {
      dispatch(fetchToken())
    },
    showLoader: () => {
      dispatch(showLoader())
    },
    hideLoader: () => {
      dispatch(hideLoader())
    }
  }
}

const IntroductionForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Introduction));

export default IntroductionForm;