import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Check from "../../../assets/images/check.png";
import Wrong from "../../../assets/images/wrong.png";
import Tablet from "../../../assets/images/tablet.png";
import Day6 from "../../../assets/images/day-6.png";
import Day6Correct from "../../../assets/images/liver/6-correct.png";
// import Day6Wrong from "../../../assets/images/liver/6-wrong.png";

class GameDay6 extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="main-container">
            <div className="container">
              <div className="content">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-header">
                      <h1 className="m-0"><span className="font-size-45 font-weight-bold text-primary-theme">Treatment</span> How to take your tablets</h1>
                      <div className="timer-box">
                        03
                      </div>
                  </div>
                  <div className="section-body flex-fill">
                    <div className="mt-4 mb-5 mt-lg-5 py-4">
                      <div className="row row-cols-2 align-items-center">
                        <div className="col text-left">
                          <img src={Day6} alt="Day6" />
                        </div>
                        <div className="col text-center">
                          <img src={Tablet} alt="Tablet" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row align-items-end mb-3 mb-lg-5">
                        <div className="col-md-3 mb-4 mb-lg-0">
                          <img src={Day6Correct} className="liver-image pr-0 pr-lg-5" alt="Day 6 Correct" />
                          {/* <img src={Day6Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 6 Wrong" /> */}
                        </div>
                        <div className="col-md-9">
                          <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
                            <div className="day-box">
                              <p className="day-text">Sun</p>
                              <img src={Check} className="day-tick" alt="Check" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Mon</p>
                              <img src={Check} className="day-tick" alt="Check" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Tue</p>
                              <img src={Check} className="day-tick" alt="Check" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Wed</p>
                              <img src={Wrong} className="day-tick" alt="Wrong" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Thu</p>
                              <img src={Wrong} className="day-tick" alt="Wrong" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Fri</p>
                              {/* <img src={Check} className="day-tick" alt="Check" /> */}
                            </div>
                            <div className="day-box">
                              <p className="day-text">Sat</p>
                              {/* <img src={Check} className="day-tick" alt="Check" /> */}
                            </div>
                            <div className="day-box">
                              <p className="day-text">Sun</p>
                              {/* <img src={Check} className="day-tick" alt="Check" /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    <h3 className="text-primary-theme font-weight-bold text-center m-0">Give the man his medicine by dragging the tablet over his mouth</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      </React.Fragment>
    );
  }
}

export default withRouter(GameDay6);