import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import MenziesLogo from "../../assets/images/menzies.svg";
import { connect } from 'react-redux';
import { fetchLanguage, fetchToken, showLoaderLanguage, hideLoaderLanguage } from "../../redux"
import Loader from "../loader/loader"
import LanguageArrow from "../../assets/images/language-arrow.svg";
// import LanguageDefault from "../../assets/images/language-default.svg";
import LanguageCheck from "../../assets/images/language-check.svg";

class Language extends PureComponent {

  constructor(props) {
    super(props)

    //this.state =  this.props.location.state || { chapterName: "Chapter" }; // In the ||, set default state.

    const locationState = this.props.location.state || {}
    const { lanid = 1, chapterName = "Chapter", lanname = "English" } = locationState

    this.state = {
      lanid: lanid,
      chapterName: chapterName,
      lanname: lanname
    }

    this.props.history.replace(this.props.location.pathname, this.state); // Update state of current entry in history stack.

  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    //console.log("componentDidUpdate")
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      //this.setState(this.props.location.state);
      this.setState(prevStates => ({
        ...prevStates,
        chapterName: this.props.location.state.chapterName,
        lanid: this.props.location.state.lanid,
        lanname: this.props.location.state.lanname,
      }));
    }
      // this.setState(this.props.location.state);
  }

  componentWillMount() {
    this.props.showLoaderLanguage()
    //console.log("Language", this.props)
    //console.log("componentDidMount")
    // const auth = localStorage.getItem('auth')
    // //console.log("auth", auth)
    // console.log("this.state.chapterName", this.state.chapterName)
    this.props.tokenData == null && this.props.fetchToken()
    this.props.fetchLanguage(this.props.tokenData.authToken.token)
  }

  handleRedirectToHome = () => {
    this.props.history.push({
      pathname: '/',
      state: {
        popup: false,
        lanid: this.state.lanid,
        lanname: this.state.lanname
      }
    });
  }

  handleRedirectToChapter = (id) => {
    // console.log("id", id)
    if(this.state.chapterName === "Women") {
      this.props.history.push({
        pathname: '/womens_business/pregnant_women',
        state: {
          id: id
        },
      });  
    } else {
      this.props.history.push({
        pathname: '/chapter',
        state: {
          id: id
        },
      });
    }
    // this.props.history.push(`/chapter/${id}`);
  };

  render() {
    return (
      this.props.loading
        ?
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content language">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-body flex-fill">
                  <Loader />
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        :
      <React.Fragment>
        <div className="wrapper">
          <div className="main-container">
            <div className="container">
              <div className="content language">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-body flex-fill">
                    {/* <div className="d-flex align-items-center globe-tag">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#b0b0b0" class="bi bi-globe mr-1" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
                      </svg>
                      Offline
                    </div> */}
                    <div className="form-row justify-content-center">
                      <div className="col-lg-8">
                        <div className="main-logo mt-4 pt-3">
                          <img src={Logo} alt="Hep B Story Logo" height="110" />
                        </div>
                          <div className="btn-container mx-auto mt-4 mb-4 mt-lg-5">
                            <div className="form-row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-center">
                            {
                              this.props.languageData != null 
                              && this.props.languageData.languages != null 
                              && this.props.languageData.languages.list !=null 
                              && this.props.languageData.languages.list.map(language => {
                                if (language.language) {
                                  return (
                                    <div key={language.id} className="col mb-3">
                                      <button type="button" onClick={() => this.handleRedirectToChapter(language.id)} className="btn btn-lg btn-success-theme btn-outline font-size-18">
                                        <img src={LanguageArrow} className="mr-2 language-arrow-default" width="35" alt="Language Arrow Default" />
                                        <img src={LanguageCheck} className="mr-2 language-arrow-check" width="35" alt="Language Arrow Check" />
                                        {language.language}
                                      </button>
                                    </div>
                                  )
                                }
                              })
                            }


                            {/* <div className="col mb-2">
                              <button type="button" onClick={this.handleRedirectToChapter} className="btn btn-lg btn-success-theme">English</button>
=======
                              {/* {
                                this.props.languageData.languages.map(language => {
                                  if (language.language) {
                                    return (
                                      <div key={language.id} className="col mb-2">
                                        <button type="button" onClick={() => this.handleRedirectToChapter(language.id)} className="btn btn-lg btn-success-theme">{language.language}</button>
                                      </div>
                                        

                                    )
                                  }
                                })
                              } */}


                              {/* <div className="col mb-2">
                                <button type="button" onClick={this.handleRedirectToChapter} className="btn btn-lg btn-success-theme">English</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Kriol</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Yolnu Matha</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Tiwi</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Arrernte</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Murrinh-Patha	</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Pitjantjatjara</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Warlpiri</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Kuwinjku</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Anindilyakwa</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Burarra</button>
                              </div>
                              <div className="col mb-2">
                                <button type="button" className="btn btn-lg btn-success-theme">Gurindji	</button>
                              </div> */}
                            </div>
                          </div>
                          <div className="menzies-logo mb-4">
                            <img src={MenziesLogo} alt="Menzies Logo" />
                          </div>
                        </div>
                      </div>
                      <div className="text-center text-md-right mt-n5">
                        <button type="button" onClick={this.handleRedirectToHome} className="btn btn-lg btn-primary-theme back-btn">
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("language mapStateToProps")
  return {
    tokenData: state.token,
    languageData: state.language,
    loading: state.language.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  //console.log("language mapDispatchToProps")
  return {
    fetchLanguage: (authToken) => {
      //console.log("language mapDispatchToProps 2", authToken)
      dispatch(fetchLanguage(authToken))
    },
    fetchToken: () => {
      dispatch(fetchToken())
    },
    showLoaderLanguage: () => {
      dispatch(showLoaderLanguage())
    },
    hideLoaderLanguage: () => {
      dispatch(hideLoaderLanguage())
    }
  }
}

// export default withRouter(Language);

const LanguageForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Language));

export default LanguageForm;
