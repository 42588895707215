import { GET_TOKEN, API_BASE_URL } from "../constants/types"
import axios from "axios"

const getToken = (token) => {
    return {
        type: GET_TOKEN,
        payload: token
    }
}

export const fetchToken = () => {

    return (dispatch) => {

        // let tokenData = new FormData();
        // tokenData.append('device_id', deviceId);
        // tokenData.append('device_type', deviceType);

        axios({
            method: "post",
            url: `${API_BASE_URL}/api/v1/common/get-token`,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                dispatch(getToken(res.data.data.token))
                //console.log(res.data)
                //console.log(res.data.data)
                //console.log(res.data.data.token)
            })
            .catch(err => {
                //console.log(err)
            })
    }
}