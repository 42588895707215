import { GET_TOKEN } from "../constants/types"

const initialState = {
    loading: false,
    authToken: '',
    error: ''
}

const tokenReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_TOKEN:
            return {
                loading: true,
                authToken: action.payload,
                error: ''
            }

        default: return state
    }
}

export default tokenReducer