import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import SoundOn from "../../../assets/images/sound-on.png";
import ArrowBack from "../../../assets/images/arrow-back.svg";
import ArrowNext from "../../../assets/images/arrow-next.svg";
// import PregnantWomen1 from "../../../assets/images/pregnant-women-1.svg";
// import PregnantWomen2 from "../../../assets/images/pregnant-women-2.svg";
//import ReactHtmlParser from "react-html-parser";
import { connect } from 'react-redux';
import { fetchChapter, showLoader, hideLoader } from "../../../redux"
import Loader from "../../loader/loader";
import Pause from "../../../assets/images/pause.png";

class PregnantWomen extends PureComponent {

  constructor(props) {
    super(props)

    this.audioRef = React.createRef();
    const locationState = this.props.location.state || {}
    const { id = 1 } = locationState

    this.state = {
      audio: null,
      isPlaying: false,
      chapter: {},
      subChapter: {},
      id: id,
      playEnd: false,
      loading: false
    }

    this.props.history.replace(this.props.location.pathname, this.state); // Update state of current entry in history stack.

  }

  end() {
    this.setState(prevState => ({
      ...prevState,
      playEnd: !prevState.playEnd,
      isPlaying: !prevState.isPlaying,
    }));
  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    //console.log("componentDidUpdate")
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      this.setState(prevStates => ({
        ...prevStates,
        id: this.props.location.state.id
      }));
      // this.setState(this.props.location.state);
    }

    if (this.props.chapterData !== prevProps.chapterData) {
      // var array = this.props.match.path.split("/");
      // let chapterVar = this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
      // this.setState(prevState => ({
      //   ...prevState,
      //   chapter: chapterVar,
      //   subChapter: chapterVar.sub_chapters.find(subchapter => subchapter.identifier === array[array.length - 1])
      // }));

      var array = this.props.match.path.split("/");
      let chapterVar = this.props.chapterData != null
        && this.props.chapterData.chapters != null
        && this.props.chapterData.chapters.list != null
        && this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
      this.setState(prevState => ({
        ...prevState,
        chapter: chapterVar,
        subChapter: chapterVar != null
          && chapterVar.sub_chapters != null
          && chapterVar.sub_chapters.find(subchapter => subchapter.identifier === array[array.length - 1])
      }));
    }

  }

  playPause = () => {
    let isPlaying = this.state.isPlaying;
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      if (isPlaying) {
        this.audioRef.current.pause();
      } else {
        this.audioRef.current.play();
      }
    }
    this.setState(prevState => ({
      ...prevState,
      isPlaying: !prevState.isPlaying
    }));
  };

  componentWillMount() {
    this.props.showLoader()

    this.setState(prevState => ({
      ...prevState,
      loading: this.props.loading
    }));
    //console.log("PregnantWomen data componentWillMount", this.props)
    // console.log("this.state.id", this.state.id)
    this.props.fetchChapter(this.state.id, this.props.tokenData.authToken.token)

    // var array = this.props.match.path.split("/");
    // //console.log(array[array.length - 1])
    // let chapterVar = this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
    // //console.log(chapterVar);
    // this.setState(prevState => ({
    //   ...prevState,
    //   chapter: chapterVar,
    //   subChapter: chapterVar.sub_chapters.find(subchapter => subchapter.identifier === array[array.length - 1])
    // }));

    var array = this.props.match.path.split("/");
    let chapterVar = this.props.chapterData != null
      && this.props.chapterData.chapters != null
      && this.props.chapterData.chapters.list != null
      && this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
    this.setState(prevState => ({
      ...prevState,
      chapter: chapterVar,
      subChapter: chapterVar != null
        && chapterVar.sub_chapters != null
        && chapterVar.sub_chapters.find(subchapter => subchapter.identifier === array[array.length - 1])
    }));

  }

  componentDidMount() {
    this.setState(prevState => ({
      ...prevState,
      loading: this.props.loading
    }));
    //console.log(this.state)
    this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0] && this.setState(prevState => ({
      ...prevState,
      audio: new Audio(this.state.subChapter.data[0].audio[0])
    }));
    var promise

    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      if (this.audioRef.current != null)
        promise = this.audioRef.current.play();
    }

    if (promise !== undefined) {
      promise.then(_ => {
        this.setState(prevState => ({
          ...prevState,
          isPlaying: true
        }));
      }).catch(error => {
        this.setState(prevState => ({
          ...prevState,
          isPlaying: false
        }));
      });
    }
    // //console.log("Introduction data componentDidMount", this.props)
    // var array = this.props.match.path.split("/");
    // //console.log(array[array.length-1])
    //window.addEventListener("focus", this.onFocus)
    window.addEventListener('blur', this.onBlur);
  }

  onFocus = () => {
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      this.audioRef.current.pause();
    }
    this.setState(prevState => ({
      ...prevState,
      isPlaying: true
    }));
  }

  onBlur = () => {
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      if (this.audioRef.current != null)
        this.audioRef.current.pause();
    }
    this.setState(prevState => ({
      ...prevState,
      isPlaying: false
    }));
  }

  componentWillUnmount() {
    //window.removeEventListener("focus", this.onFocus)
    window.removeEventListener('blur', this.onBlur);
  }

  handleRedirect = (pathName) => {
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      this.audioRef.current.pause();
    }
    this.setState(prevState => ({
      ...prevState,
      isPlaying: !prevState.isPlaying
    }));
    this.props.history.push({
      pathname: pathName,
      state: {
        id: this.state.id
      },
    });
  }

  start() {
    //console.log("started")
    var promise

    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      if (this.audioRef.current != null)
        promise = this.audioRef.current.play();
    }

    if (promise !== undefined) {
      promise.then(_ => {
        this.setState(prevState => ({
          ...prevState,
          isPlaying: true
        }));
      }).catch(error => {
        this.setState(prevState => ({
          ...prevState,
          isPlaying: false
        }));
      });
    }
  }

  render() {
    return (
      this.props.loading
        ?
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content womens-business">
                  <div className="section d-flex flex-column flex-fill">
                    <div className="section-body flex-fill">
                      <Loader />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        :
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content womens-business">
                  <div className="section d-flex flex-column flex-fill">
                    <div className="section-header">
                      <h1 className="m-0">
                        {
                          this.state.id === 1 ?
                            <><span className="font-size-45 font-weight-bold text-primary-theme">{this.state.chapter.chapter_name} </span>
                              {this.state.subChapter.sub_chapter_name}</>
                            :
                            <span className="font-size-45 font-weight-bold text-primary-theme">{this.state.subChapter.sub_chapter_name}</span>
                        }
                      </h1>
                      {/* <Link to="" className="sound-icon">
                        <img src={SoundOn} alt="Sound" />
                      </Link> */}
                      <button onClick={this.playPause} className="sound-icon btn-sound">
                        {
                          this.state.isPlaying
                            ? <img src={Pause} alt="Pause" />
                            : <img src={SoundOn} alt="Sound" />
                        }
                      </button>
                    </div>
                    <div className="section-body d-md-flex flex-md-column flex-fill justify-content-around">
                      <div className="section-image mb-3 mb-lg-0">
                        <div className="row row-cols-1 justify-content-lg-center">

                          {
                            this.state.subChapter.data && this.state.subChapter.data[0] &&
                            this.state.subChapter.data[0].image.map((imageName, index) => {
                              return (
                                <div key={index} className="col-auto mb-3 mb-lg-0">
                                  <img src={imageName} alt={this.state.subChapter.sub_chapter_name} />
                                </div>
                              )
                            })
                          }

                          {
                            this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0] &&
                            <audio ref={this.audioRef} onEnded={() => this.end()} src={this.state.subChapter.data[0].audio[0]} onLoadStart={() => this.start()} autoPlay />
                          }

                          {/* {
                          this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]
                            ? <video ref={this.vidRef} src={this.state.subChapter.data[0].video[0]} autoPlay />
                            : this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0] &&
                            <div>
                              <audio ref={this.audioRef} src={this.state.subChapter.data[0].audio[0]} autoPlay />
                              {this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].image && this.state.subChapter.data[0].image[0] && <img ref={this.imgRef} src={this.state.subChapter.data[0].image[0]} alt={this.state.subChapter.sub_chapter_name} />}
                            </div>
                        } */}


                          {/* <div className="col-auto mb-3 mb-lg-0">
                          <img src={PregnantWomen1} alt="Pregnant Women 1" />
                        </div>
                        <div className="col-auto">
                          <img src={PregnantWomen2} alt="Pregnant Women 2" />
                        </div> */}
                        </div>
                      </div>
                      <div className="text-left">
                        {
                          this.state.subChapter.data
                          && this.state.subChapter.data[0]
                          && this.state.subChapter.data[0].content.split("\n").map((item, idx) => {
                            return (
                              <span key={idx}>
                                {item}
                                <br />
                              </span>
                            )
                          })
                        }
                        {/* {ReactHtmlParser(this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].content)} */}
                        <br />
                        <a href="https://bmcpublichealth.biomedcentral.com/articles/10.1186/1471-2458-14-1233" target="_blank" className="link" >Davies et al. (2014),</a>
                        <a href="https://www.researchgate.net/publication/278042538_Development_of_a_Culturally_Appropriate_Bilingual_Electronic_App_About_Hepatitis_B_for_Indigenous_Australians_Towards_Shared_Understandings" target="_blank" className="link"> Davies et al. (2015)</a>
                      </div>
                    </div>
                    <div className="section-footer">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <button onClick={() => this.handleRedirect("/treatment/treatment_importance")} className="btn-sound">
                          <img src={ArrowBack} alt="Arrow Back" />
                        </button>
                        <button onClick={() => this.handleRedirect("/chapter")} className="btn-sound">
                          <h3 className="text-primary-theme m-0">Select Chapter</h3>
                        </button>
                        <button onClick={() => this.handleRedirect("/womens_business/treatment_for_baby")} className="btn-sound">
                          <img src={ArrowNext} alt="Arrow Next" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  //console.log("chapter mapStateToProps")
  return {
    tokenData: state.token,
    chapterData: state.chapter,
    loading: state.chapter.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  //console.log("chapter mapDispatchToProps")
  return {
    fetchChapter: (languageid, authToken) => {
      //console.log("chapter mapDispatchToProps 2", authToken)
      dispatch(fetchChapter(languageid, authToken))
    },
    showLoader: () => {
      dispatch(showLoader())
    },
    hideLoader: () => {
      dispatch(hideLoader())
    }
  }
}

const PregnantWomenForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PregnantWomen));

export default PregnantWomenForm;