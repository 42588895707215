import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import ArrowNext from "../../../assets/images/arrow-next.svg";
import Check from "../../../assets/images/check.png";
import Wrong from "../../../assets/images/wrong.png";
import Day7Wrong from "../../../assets/images/liver/7-wrong.png";

class GameFailure extends PureComponent {

  handleRedirectToGameLevel = () => {
    this.props.history.push("/treatment/game_level");
  };

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="main-container">
            <div className="container">
              <div className="content">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-header">
                      <h1 className="m-0"><span className="font-size-45 font-weight-bold text-primary-theme">Treatment</span> How to take your tablets</h1>
                      <Link to="" className="play-icon">
                        <img src={ArrowNext} alt="Arrow Next" />
                      </Link>
                  </div>
                  <div className="section-body flex-fill">
                    <div className="game-result-container failure my-4 my-lg-5">
                      <div className="d-flex align-items-center text-left">
                        <img src={Wrong} alt="Wrong" className="mr-4" />
                        <h3 className="font-weight-bold m-0">You missed too many tablets! This person's liver is still sick and the Hep B is becoming resistant to the medicine. Please try again.</h3>
                      </div>
                      <button type="button" onClick={this.handleRedirectToGameLevel} className="btn btn-lg btn-success-theme w-auto mb-4">Play Again?</button>
                    </div>
                    <div className="form-row align-items-end mb-3 mb-lg-5">
                        <div className="col-md-3 mb-4 mb-lg-0">
                          <img src={Day7Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 7 Correct" />
                        </div>
                        <div className="col-md-9">
                          <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
                            <div className="day-box">
                              <p className="day-text">Sun</p>
                              <img src={Wrong} className="day-tick" alt="Wrong" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Mon</p>
                              <img src={Wrong} className="day-tick" alt="Wrong" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Tue</p>
                              <img src={Wrong} className="day-tick" alt="Wrong" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Wed</p>
                              <img src={Wrong} className="day-tick" alt="Wrong" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Thu</p>
                              <img src={Wrong} className="day-tick" alt="Wrong" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Fri</p>
                              <img src={Check} className="day-tick" alt="Check" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Sat</p>
                              <img src={Wrong} className="day-tick" alt="Wrong" />
                            </div>
                            <div className="day-box">
                              <p className="day-text">Sun</p>
                              <img src={Check} className="day-tick" alt="Check" />
                            </div>
                          </div>
                        </div>
                      </div>
                    <h3 className="text-primary-theme font-weight-bold text-center m-0">Give the man his medicine by dragging the tablet over his mouth</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      </React.Fragment>
    );
  }
}

export default withRouter(GameFailure);