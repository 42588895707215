import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import ArrowBack from "../../assets/images/arrow-back.svg";
import ArrowNext from "../../assets/images/arrow-next.svg";
import MenziesBig from "../../assets/images/menzies-big.svg";
import { connect } from 'react-redux';
import { fetchChapter, fetchToken, showLoader, hideLoader, fetchLanguage } from "../../redux"
import Loader from "../loader/loader"
import ReactHtmlParser from "react-html-parser";

class Acknowledgements extends PureComponent {

  constructor(props) {
    super(props)

    const locationState = this.props.location.state || {}
    const { id = 1, lanname = "English" } = locationState
  
    this.state = {
      id: id,
      lanname: lanname
    }

    this.props.history.replace(this.props.location.pathname, this.state);
  }

  handleRedirect = (pathName) => {
    if(pathName === "/") {
      this.props.history.push({
        pathname: pathName,
        state: {
          lanid: this.state.id,
          popup: false,
          lanname: this.state.lanname
        }
      });
    } else {
      this.props.history.push({
        pathname: pathName,
        state: {
          id: this.state.id
        }
      });
    }
  }

  componentWillMount() {
    this.props.showLoader()
    this.props.fetchLanguage(this.props.tokenData.authToken.token)

    let languageVar = this.props.languageData != null
      && this.props.languageData.languages != null
      && this.props.languageData.languages.list != null
      && this.props.languageData.languages.list.find(language => language.id === this.state.id);
    this.setState(prevState => ({
      ...prevState,
      lanname: languageVar.language
    }));
    this.props.fetchChapter(this.state.id, this.props.tokenData.authToken.token)
  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    //console.log("componentDidUpdate")
    if (this.props.location !== prevProps.location) {
      this.setState(prevStates => ({
        ...prevStates,
        id: this.props.location.state.id,
        lanname: this.props.location.state.lanname
      }));
      // this.setState(this.props.location.state);
    }

    if (this.props.languageData !== prevProps.languageData) {

      let languageVar = this.props.languageData != null
        && this.props.languageData.languages != null
        && this.props.languageData.languages.list != null
        && this.props.languageData.languages.list.find(language => language.id === this.state.id);
      this.setState(prevState => ({
        ...prevState,
        lanname: languageVar.language
      }));

    }
  }

  render() {
    return (
      this.props.loading
        ?
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content acknowledgements">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-body flex-fill">
                  <Loader />
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        :
      <React.Fragment>
        <div className="wrapper">
          <div className="main-container">
            <div className="container">
              <div className="content acknowledgements">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-header">
                    <h1 className="m-0"><span className="font-size-45 font-weight-bold text-primary-theme">{this.props.chapterData.chapters.acknowledgement.acknowledgement_name}</span></h1>
                  </div>
                  <div className="section-body d-md-flex flex-md-column flex-fill justify-content-around p-0">
                  {
                    ReactHtmlParser(this.props.chapterData.chapters.acknowledgement.acknowledgement_description)
                  }
                  </div>

                  {/* <div className="section-body flex-fill">
                    <div className="row row-cols-1 row-cols-lg-2 justify-content-lg-center font-size-16">
                      <div className="col mb-3 mb-lg-0">
                        <h6 className="font-weight-bold text-primary-theme">Authors</h6>
                        <p>Jane Davies <sup>1.3</sup>, Sarah Bukulatjpi <sup>2</sup>, Suresh Sharma <sup>3</sup>, <br /> Vanessa Johnston <sup>1</sup>, Joshua S Davis <sup>1.4</sup> </p>
                        <ul className="list-unstyled">
                          <li>1. Global and Tropical Health Division, Menzies School of Health Research, Darwin, NT</li>
                          <li>2. Ngalkanbuy Clinic, Miwatj Health, Galiwinku, NT</li>
                          <li>3. Liver Clinic, Royal Darwin Hospital, Darwin, NT</li>
                          <li>4. Department of Infectious Diseases, John Hunter Hospital, Newcastle NSW</li>
                        </ul>
                        <h6 className="font-weight-bold text-primary-theme">Design and development of software</h6>
                        <p>Dreamedia - Luci Caldwell, Chris Torralba, Krupa Patel <br /> with input from Menzies Communications unit <br /> (Aoife Dowling and Alice Plate)</p>
                        <h6 className="font-weight-bold text-primary-theme">Translation team</h6>
                        <p>Maratja Dhamarrandji, Dorothy Gapany</p>
                        <h6 className="font-weight-bold text-primary-theme">Voiceovers</h6>
                        <p>Maratja Dhamarrandji, Dorothy Gapany, <br /> Tristan Etherington and Christine Mills</p>
                        <h6 className="font-weight-bold text-primary-theme">Funding</h6>
                        <p>This app was produced by the Menzies School of Health Research with funding support from an unrestricted educational grant from Gilead sciences. Salary support was provided by the National Health and Medical Research Council (JD & JSD), The Sidney Myer Foundation (JD) & Miwatj Health Aboriginal Corporation (SB).</p>
                      </div>
                      <div className="col">
                        <p>Most of all, the authors would like to thank: the people of Galiwinku (community members, people living with Hepatitis B, and health workers from Miwatj Health and Ngalkanbuy Clinic) and the staff of the Royal Darwin Hospital liver clinic and associated health professionals. The interviews they provided formed the evidence base for this app and their ongoing evaluation and input into its development has been greatly appreciated. Its production would not have been possible without them.</p>
                        <img src={MenziesBig} alt="Menzies Big Logo" className="mt-2 mt-lg-4 mb-4 mb-lg-5" />
                        <p>John Mathews Building (58) <br /> Royal Darwin Hospital Campus Rocklands Drv, <br /> Tiwi NT 0810 Australia  <br />
                          Po Box 41096 <br /> Casuarina NT 0811 Australia <br /> Phone: (08) 8922 8196 <br /> Fax: (08) 8927 5187 <br />
                          Email: info@menzies.edu.au <br /> Website: www.menzies.edu.au
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="section-footer">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      {/* <Link to="/treatment/treatment_importance">
                        <img src={ArrowBack} alt="Arrow Back" />
                      </Link>
                      <Link to="/chapter" style={{ textDecoration: "none" }}>
                        <h3 className="text-primary-theme m-0">Select Chapter</h3>
                      </Link>
                      <Link to="/womens_business/pregnant_women">
                        <img src={ArrowNext} alt="Arrow Next" />
                      </Link> */}
                      <button onClick={() => this.handleRedirect("/womens_business/treatment_paths")} className="btn-sound">
                        <img src={ArrowBack} alt="Arrow Back" />

                      </button>
                      <button onClick={() => this.handleRedirect("/chapter")} className="btn-sound">
                        <h3 className="text-primary-theme m-0">Select Chapter</h3>
                      </button>
                      <button onClick={() => this.handleRedirect("/")} className="btn-sound">
                        <img src={ArrowNext} alt="Arrow Next" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    tokenData: state.token,
    chapterData: state.chapter,
    loading: state.chapter.loading,
    languageData: state.language
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChapter: (languageid, authToken) => {
      dispatch(fetchChapter(languageid, authToken))
    },
    fetchLanguage: (authToken) => {
      dispatch(fetchLanguage(authToken))
    },
    fetchToken: () => {
      dispatch(fetchToken())
    },
    showLoader: () => {
      dispatch(showLoader())
    },
    hideLoader: () => {
      dispatch(hideLoader())
    }
  }
}

const AcknowledgementsForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Acknowledgements));

export default AcknowledgementsForm;

//export default withRouter(Acknowledgements);