import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Check from "../../../assets/images/check.png";
import Wrong from "../../../assets/images/wrong.png";
import Tablet from "../../../assets/images/tablet.png";
import Day1 from "../../../assets/images/day-1.png";
import Day2 from "../../../assets/images/day-2.png";
import Day3 from "../../../assets/images/day-3.png";
import Day4 from "../../../assets/images/day-4.png";
import Day5 from "../../../assets/images/day-5.png";
import Day6 from "../../../assets/images/day-6.png";
import Day7 from "../../../assets/images/day-7.png";
import Day8 from "../../../assets/images/day-8.png";
import Day1Correct from "../../../assets/images/liver/1-correct.png";
import Day2Correct from "../../../assets/images/liver/2-correct.png";
import Day3Correct from "../../../assets/images/liver/3-correct.png";
import Day4Correct from "../../../assets/images/liver/4-correct.png";
import Day5Correct from "../../../assets/images/liver/5-correct.png";
import Day6Correct from "../../../assets/images/liver/6-correct.png";
import Day7Correct from "../../../assets/images/liver/7-correct.png";
import Day8Correct from "../../../assets/images/liver/8-correct.png";
import Day1Wrong from "../../../assets/images/liver/1-wrong.png";
import Day2Wrong from "../../../assets/images/liver/2-wrong.png";
import Day3Wrong from "../../../assets/images/liver/3-wrong.png";
import Day4Wrong from "../../../assets/images/liver/4-wrong.png";
import Day5Wrong from "../../../assets/images/liver/5-wrong.png";
import Day6Wrong from "../../../assets/images/liver/6-wrong.png";
import Day7Wrong from "../../../assets/images/liver/7-wrong.png";
import Day8Wrong from "../../../assets/images/liver/8-wrong.png";
import ArrowNext from "../../../assets/images/arrow-next.svg";
import ArrowBack from "../../../assets/images/arrow-back.svg";
//import Draggable from 'react-draggable';
// import { timers } from "jquery";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from 'react-redux';
import { fetchChapter } from "../../../redux"


class GameDay1 extends PureComponent {

  interval

  constructor(props) {
    super(props)

    const locationState = this.props.location.state || {}
    const { id = 1, gameLevel = "Easy", pageName = "how_to_take_your_tablets" } = locationState

    this.dragRef = React.createRef();
    this.tabRef = React.createRef();
    this.dayRef = React.createRef();

    this.state = {
      countMedicine: 0,
      timer: 5,
      pageNumber: 1,
      medicine: 0,
      sunMedicine: 0,
      monMedicine: 0,
      tueMedicine: 0,
      wedMedicine: 0,
      thuMedicine: 0,
      friMedicine: 0,
      satMedicine: 0,
      sun2Medicine: 0,
      id: id,
      gameLevel: gameLevel,
      pageName: pageName,
      chapter: {},
      subChapter: {}
    }

    this.props.history.replace(this.props.location.pathname, this.state); // Update state of current entry in history stack.

  }

  setTimer() {
    if (this.state.gameLevel === "Easy") {
      //this.setState({ timer: 5 })
      this.setState(prevStates => ({
        ...prevStates,
        timer: 5
      }));
    } else if (this.state.gameLevel === "Medium") {
      this.setState(prevStates => ({
        ...prevStates,
        timer: 4
      }));
    } else if (this.state.gameLevel === "Hard") {
      this.setState(prevStates => ({
        ...prevStates,
        timer: 3
      }));
    }
  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.setState(prevStates => ({
        ...prevStates,
        gameLevel: this.props.location.state.gameLevel,
        id: this.props.location.state.id,
        pageName: this.props.location.state.pageName
      }));
    }

    if (this.props.chapterData !== prevProps.chapterData) {
      var array = this.props.match.path.split("/");
      let chapterVar = this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
      this.setState(prevState => ({
        ...prevState,
        chapter: chapterVar,
        subChapter: chapterVar.sub_chapters.find(subchapter => subchapter.identifier === this.state.pageName)
      }));
    }

    if (this.state.timer === 0) {
      // this.setState(prevState => ({
      //   pageNumber: prevState.pageNumber + 1
      // }))

      // this.setState({ medicine: 2 })

      this.setState(prevStates => ({
        ...prevStates,
        pageNumber: prevStates.pageNumber + 1,
        medicine: 2
      }));

      if (this.state.pageNumber === 1) {
        //this.setState({ sunMedicine: 2 })
        this.setState(prevStates => ({
          ...prevStates,
          sunMedicine: 2
        }));
      } else if (this.state.pageNumber === 2) {
        //this.setState({ monMedicine: 2 })
        this.setState(prevStates => ({
          ...prevStates,
          monMedicine: 2
        }));
      } else if (this.state.pageNumber === 3) {
        //this.setState({ tueMedicine: 2 })
        this.setState(prevStates => ({
          ...prevStates,
          tueMedicine: 2
        }));
      } else if (this.state.pageNumber === 4) {
        //this.setState({ wedMedicine: 2 })
        this.setState(prevStates => ({
          ...prevStates,
          wedMedicine: 2
        }));
      } else if (this.state.pageNumber === 5) {
        //this.setState({ thuMedicine: 2 })
        this.setState(prevStates => ({
          ...prevStates,
          thuMedicine: 2
        }));
      } else if (this.state.pageNumber === 6) {
        //this.setState({ friMedicine: 2 })
        this.setState(prevStates => ({
          ...prevStates,
          friMedicine: 2
        }));
      } else if (this.state.pageNumber === 7) {
        //this.setState({ satMedicine: 2 })
        this.setState(prevStates => ({
          ...prevStates,
          satMedicine: 2
        }));
      } else if (this.state.pageNumber === 8) {
        //this.setState({ sun2Medicine: 2 })
        this.setState(prevStates => ({
          ...prevStates,
          sun2Medicine: 2
        }));
      }

      this.setTimer()
    }
    if (this.state.pageNumber === 9) {
      clearInterval(this.interval)
    }
  }

  componentWillMount() {
    // if (this.state.gameLevel === "Easy") {
    //   this.setState({ timer: 5 })
    // } else if (this.state.gameLevel === "Medium") {
    //   this.setState({ timer: 4 })
    // } else if (this.state.gameLevel === "Hard") {
    //   this.setState({ timer: 3 })
    // }

    this.props.fetchChapter(this.state.id, this.props.tokenData.authToken.token)
    var array = this.props.match.path.split("/");
    let chapterVar = this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
    this.setState(prevState => ({
      ...prevState,
      chapter: chapterVar,
      subChapter: chapterVar.sub_chapters.find(subchapter => subchapter.identifier === this.state.pageName)
    }));

    this.setTimer()
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState(prevState => ({
      ...prevState,
      timer: prevState.timer - 1
    })), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleRedirect = (pathName) => {
    //this.props.history.push(pathName);
    this.props.history.push({
      pathname: pathName,
      state: {
        id: this.state.id,
        pageName: this.state.pageName
      },
    });
  }

  handleRedirectToGameLevel = () => {
    //this.props.history.push("/treatment/game_level");
    this.props.history.push({
      pathname: '/treatment/game_level',
      state: {
        id: this.state.id,
        pageName: this.state.pageName
      },
    });
  };

  handleDragStart = (event, eventName) => {
    // console.log(eventName)
    // console.log(event.target)
  }

  handleOnDragEnd = (result, pageNo) => {
    const { destination, draggableId, source, type } = result;
    //console.log("result", result)
    if (!destination) {
      return;
    }

    if (pageNo === 1) {

      if (destination.droppableId === source.droppableId && destination.index === source.index) {

        //document.getElementById("imgId1").className = "ml-4 notransition"
        
        // this.setState(prevState => ({
        //   pageNumber: prevState.pageNumber + 1
        // }))

        // this.setState({ medicine: 1 })

        // this.setState({ sunMedicine: 1 })

        // this.setState(prevState => ({
        //   countMedicine: prevState.countMedicine + 1
        // }))

        this.setState(prevStates => ({
          ...prevStates,
          pageNumber: prevStates.pageNumber + 1,
          medicine: 1,
          sunMedicine: 1,
          countMedicine: prevStates.countMedicine + 1
        }));

        // if (this.state.gameLevel === "Easy") {
        //   this.setState({ timer: 5 })
        // } else if (this.state.gameLevel === "Medium") {
        //   this.setState({ timer: 4 })
        // } else if (this.state.gameLevel === "Hard") {
        //   this.setState({ timer: 3 })
        // }

        this.setTimer()

      } else {
        //this.setState({ medicine: 2 })
        this.setState({ sunMedicine: 2 })
      }

    } else if (pageNo === 2) {

      if (destination.droppableId === source.droppableId && destination.index === source.index) {

        this.setState(prevStates => ({
          ...prevStates,
          pageNumber: prevStates.pageNumber + 1,
          medicine: 1,
          monMedicine: 1,
          countMedicine: prevStates.countMedicine + 1
        }));

        this.setTimer()

      } else {
        //this.setState({ medicine: 2 })
        this.setState({ monMedicine: 2 })
      }

    } else if (pageNo === 3) {

      if (destination.droppableId === source.droppableId && destination.index === source.index) {

        this.setState(prevStates => ({
          ...prevStates,
          pageNumber: prevStates.pageNumber + 1,
          medicine: 1,
          tueMedicine: 1,
          countMedicine: prevStates.countMedicine + 1
        }));

        this.setTimer()

      } else {
        //this.setState({ medicine: 2 })
        this.setState({ tueMedicine: 2 })
      }
    } else if (pageNo === 4) {

      if (destination.droppableId === source.droppableId && destination.index === source.index) {

        this.setState(prevStates => ({
          ...prevStates,
          pageNumber: prevStates.pageNumber + 1,
          medicine: 1,
          wedMedicine: 1,
          countMedicine: prevStates.countMedicine + 1
        }));

        this.setTimer()

      } else {
        //this.setState({ medicine: 2 })
        this.setState({ wedMedicine: 2 })
      }
    } else if (pageNo === 5) {

      if (destination.droppableId === source.droppableId && destination.index === source.index) {

        this.setState(prevStates => ({
          ...prevStates,
          pageNumber: prevStates.pageNumber + 1,
          medicine: 1,
          thuMedicine: 1,
          countMedicine: prevStates.countMedicine + 1
        }));

        this.setTimer()

      } else {
        //this.setState({ medicine: 2 })
        this.setState({ thuMedicine: 2 })
      }
    } else if (pageNo === 6) {

      if (destination.droppableId === source.droppableId && destination.index === source.index) {

        
        this.setState(prevStates => ({
          ...prevStates,
          pageNumber: prevStates.pageNumber + 1,
          medicine: 1,
          friMedicine: 1,
          countMedicine: prevStates.countMedicine + 1
        }));

        this.setTimer()

      } else {
        //this.setState({ medicine: 2 })
        this.setState({ friMedicine: 2 })
      }
    } else if (pageNo === 7) {

      if (destination.droppableId === source.droppableId && destination.index === source.index) {

        this.setState(prevStates => ({
          ...prevStates,
          pageNumber: prevStates.pageNumber + 1,
          medicine: 1,
          satMedicine: 1,
          countMedicine: prevStates.countMedicine + 1
        }));

        this.setTimer()

      } else {
        //this.setState({ medicine: 2 })
        this.setState({ satMedicine: 2 })
      }
    } else if (pageNo === 8) {

      if (destination.droppableId === source.droppableId && destination.index === source.index) {

        this.setState(prevStates => ({
          ...prevStates,
          pageNumber: prevStates.pageNumber + 1,
          medicine: 1,
          sun2Medicine: 1,
          countMedicine: prevStates.countMedicine + 1
        }));

        this.setTimer()

      } else {
        //this.setState({ medicine: 2 })
        this.setState({ sun2Medicine: 2 })
      }
    }
  }

  handleMouseEnter(e) {
    //console.log('onMouseEnter', e.pageX, e.pageY)
    e.target.style.cursor = 'pointer'
  }
  handleMouseMove(e) {
    console.log('onMouseMove', e.pageX, e.pageY)
  }
  handleMouseLeave(e) {
    //console.log('onMouseLeave', e.pageX, e.pageY)
    e.target.style.cursor = 'default'
  }

  handleMouseOver(e) {
    console.log('onMouseOver', e.pageX, e.pageY)
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="main-container">
            <div className="container">
              <div className="content">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-header">
                    {/* <h1 className="m-0"><span className="font-size-45 font-weight-bold text-primary-theme">Treatment</span> How to take your tablets</h1> */}
                    <h1 className="m-0">
                      {
                        this.state.id === 1 ?
                        <><span className="font-size-45 font-weight-bold text-primary-theme">{this.state.chapter.chapter_name} </span> 
                        {this.state.subChapter.sub_chapter_name}</>
                        :
                        <span className="font-size-45 font-weight-bold text-primary-theme">{this.state.subChapter.sub_chapter_name}</span> 
                      }
                    </h1>
                    {
                      this.state.pageNumber < 9 && <div className="timer-box">
                        {this.state.timer}
                      </div>
                    }
                  </div>
                  <div className="section-body flex-fill">
                    <div className="mt-4 mb-3 mt-lg-5 py-4">
                      {
                        this.state.pageNumber === 1 &&

                        <div className="game-container">
                          <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, this.state.pageNumber)}>
                            <Droppable droppableId="0">
                              {(provided, snapshot) => (
                              <div className="row row-cols-2 align-items-center w-100">
                                <div ref={this.dragRef} className="col text-left">
                                    <Draggable key="0" draggableId="0" index={0}>
                                      {(provided, snapshot) => (
                                        <img
                                          id="imgId1"
                                          style={provided.draggableProps.style}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          src={Tablet} className="ml-4" alt="Tablet" />
                                      )}
                                    </Draggable>
                                </div>
                                <div className="col text-right game-image">
                                    <img ref={this.dayRef} src={Day1} alt="Day1" />
                                    <div className="game-image-placeholder day-one"
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {provided.placeholder}
                                    </div>
                                </div>
                              </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          
                        </div>
                      }
                      {
                        this.state.pageNumber === 2 &&

                        <div className="game-container">
                            <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, this.state.pageNumber)}>
                              <Droppable droppableId="1">
                                {(provided, snapshot) => (
                                  <div className="row row-cols-2 align-items-center">
                                    <div className="col game-image text-left">
                                      <img src={Day2} alt="Day2" />
                                      <div
                                        className="game-image-placeholder day-two"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {provided.placeholder}
                                      </div>
                                    </div>
                                    <div ref={this.dragRef} className="col text-center">
                                      <Draggable key="1" draggableId="1" index={1}>
                                        {(provided, snapshot) => (
                                          <img
                                            id="imgId2"
                                            style={provided.draggableProps.style}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            src={Tablet} alt="Tablet" />
                                        )}
                                      </Draggable>
                                    </div>
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                        </div>
                      }
                      {
                        this.state.pageNumber === 3 &&
                          <div className="game-container">
                            <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, this.state.pageNumber)}>
                              <Droppable droppableId="2">
                                {(provided, snapshot) => (
                                  <div className="row row-cols-2 align-items-center">
                                      <div ref={this.dragRef} className="col text-left">
                                          <Draggable key="2" draggableId="2" index={2}>
                                            {(provided, snapshot) => (
                                              <img
                                                id="imgId3"
                                                style={provided.draggableProps.style}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                src={Tablet} className="pl-0 pl-lg-4" alt="Tablet" />
                                            )}
                                          </Draggable>
                                        </div>
                                      <div className="col game-image text-right">
                                          <img src={Day3} alt="Day3" />
                                          <div
                                            className="game-image-placeholder day-three"
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                          >
                                            {provided.placeholder}
                                          </div>
                                      </div>
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                        </div>
                      }
                      {
                        this.state.pageNumber === 4 &&
                       <div className="game-container">
                          <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, this.state.pageNumber)}>
                            <Droppable droppableId="3">
                              {(provided, snapshot) => (
                                <div className="row row-cols-2 align-items-end">
                                  <div className="col game-image text-left">
                                      <img src={Day4} className="day-4-img" alt="Day4" />
                                      <div
                                        className="game-image-placeholder day-four"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {provided.placeholder}
                                      </div>
                                  </div>
                                  <div ref={this.dragRef} className="col text-center">
                                      <Draggable key="3" draggableId="3" index={3}>
                                        {(provided, snapshot) => (
                                          <img
                                            id="imgId4"
                                            style={provided.draggableProps.style}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            src={Tablet} className="ml-0 ml-lg-5 pl-0 pl-lg-5" alt="Tablet" />
                                        )}
                                      </Draggable>
                                    </div>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                      </div>
                      }
                      {
                        this.state.pageNumber === 5 &&

                        <div className="game-container">
                          <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, this.state.pageNumber)}>
                            <Droppable droppableId="4">
                              {(provided, snapshot) => (
                                <div className="row row-cols-2 align-items-center">
                                    <div ref={this.dragRef} className="col text-left">
                                      <Draggable key="4" draggableId="4" index={4}>
                                        {(provided, snapshot) => (
                                          <img
                                            id="imgId5"
                                            style={provided.draggableProps.style}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            src={Tablet} className="ml-0 ml-lg-4" alt="Tablet" />
                                        )}
                                      </Draggable>
                                    </div>
                                    <div className="col game-image">
                                      <img src={Day5} className="ml-0 ml-lg-5 pl-0 pl-lg-5" alt="Day5" />
                                      <div
                                        className="game-image-placeholder day-five"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                        {provided.placeholder}
                                      </div>
                                    </div>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      }
                      {
                        this.state.pageNumber === 6 &&

                        <div className="game-container">
                          <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, this.state.pageNumber)}>
                            <Droppable droppableId="5">
                              {(provided, snapshot) => (
                                <div className="row row-cols-2 align-items-center">
                                  <div className="col game-image text-left">
                                    <img src={Day6} alt="Day6" />
                                    <div
                                      className="game-image-placeholder day-six"
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                      {provided.placeholder}
                                    </div>
                                  </div>
                                  <div ref={this.dragRef} className="col text-center">
                                      <Draggable key="5" draggableId="5" index={5}>
                                        {(provided, snapshot) => (
                                          <img
                                            id="imgId6"
                                            style={provided.draggableProps.style}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            src={Tablet} alt="Tablet" />
                                        )}
                                      </Draggable>
                                    </div>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      }
                      {
                        this.state.pageNumber === 7 &&

                        <div className="game-container">
                          <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, this.state.pageNumber)}>
                            <Droppable droppableId="6">
                              {(provided, snapshot) => (
                                <div className="row row-cols-2 align-items-center">
                                  <div ref={this.dragRef} className="col text-left">
                                    <Draggable key="6" draggableId="6" index={6}>
                                      {(provided, snapshot) => (
                                        <img
                                          id="imgId7"
                                          style={provided.draggableProps.style}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          src={Tablet} className="ml-0 ml-lg-4" alt="Tablet" />
                                      )}
                                    </Draggable>
                                  </div>
                                  <div className="col game-image text-right">
                                    <img src={Day7} className="day-7-img" alt="Day7" />
                                    <div
                                      className="game-image-placeholder day-seven"
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {provided.placeholder}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          
                        </div>
                      }
                      {
                        this.state.pageNumber === 8 &&

                        <div className="game-container">
                          <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, this.state.pageNumber)}>
                            <Droppable droppableId="7">
                              {(provided, snapshot) => (
                                <div className="row row-cols-2 align-items-end">
                                  <div className="col game-image text-left">
                                    <img src={Day8} className="day-8-img" alt="Day8" />
                                    <div
                                      className="game-image-placeholder day-eight"
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {provided.placeholder}
                                    </div>
                                  </div>
                                  <div ref={this.dragRef} className="col text-right">
                                    <Draggable key="7" draggableId="7" index={7}>
                                      {(provided, snapshot) => (
                                        <img
                                          id="imgId8"
                                          style={provided.draggableProps.style}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          src={Tablet} className="pr-3 pr-lg-5 mr-3 mr-lg-5" alt="Tablet" />
                                      )}
                                    </Draggable>
                                  </div>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      }
                      {
                        this.state.pageNumber === 9 && this.state.countMedicine >= 6 &&

                        <div className="game-result-container">
                          <div className="d-flex align-items-center text-left mb-4 mb-lg-5">
                            <img src={Check} alt="Check" className="mr-4" />
                            <h3 className="font-weight-bold m-0">Well done, you have kept this person's liver healthy.</h3>
                          </div>
                          <button type="button" onClick={this.handleRedirectToGameLevel} className="btn btn-lg btn-success-theme w-auto mb-4">Play Again?</button>
                        </div>
                      }
                      {
                        this.state.pageNumber === 9 && this.state.countMedicine <= 5 &&
                        <div className="game-result-container failure">
                          <div className="d-flex align-items-center text-left mb-4 mb-lg-5">
                            <img src={Wrong} alt="Wrong" className="mr-4" />
                            <h3 className="font-weight-bold m-0">You missed too many tablets! This person's liver is still sick and the Hep B is becoming resistant to the medicine. Please try again.</h3>
                          </div>
                          <button type="button" onClick={this.handleRedirectToGameLevel} className="btn btn-lg btn-success-theme w-auto mb-4">Play Again?</button>
                        </div>
                      }
                    </div>
                    <div className="form-row align-items-end mb-3 mb-lg-5">
                      <div className="col-md-3 mb-4 mb-lg-0">

                        {
                          this.state.pageNumber === 1 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day1Correct} className="liver-image pr-0 pr-lg-5" alt="Day 1 Correct" />
                        }
                        {
                          this.state.pageNumber === 1 && this.state.medicine === 2 && <img src={Day1Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 1 Wrong" />
                        }
                        {
                          this.state.pageNumber === 2 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day2Correct} className="liver-image pr-0 pr-lg-5" alt="Day 2 Correct" />
                        }
                        {
                          this.state.pageNumber === 2 && this.state.medicine === 2 && <img src={Day2Correct} className="liver-image pr-0 pr-lg-5" alt="Day 2 Wrong" />
                        }
                        {
                          this.state.pageNumber === 3 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day2Correct} className="liver-image pr-0 pr-lg-5" alt="Day 3 Correct" />
                        }
                        {
                          this.state.pageNumber === 3 && this.state.medicine === 2 && <img src={Day2Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 3 Wrong" />
                        }
                        {
                          this.state.pageNumber === 4 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day3Correct} className="liver-image pr-0 pr-lg-5" alt="Day 4 Correct" />
                        }
                        {
                          this.state.pageNumber === 4 && this.state.medicine === 2 && <img src={Day3Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 4 Wrong" />
                        }
                        {
                          this.state.pageNumber === 5 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day4Correct} className="liver-image pr-0 pr-lg-5" alt="Day 5 Correct" />
                        }
                        {
                          this.state.pageNumber === 5 && this.state.medicine === 2 && <img src={Day4Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 5 Wrong" />
                        }
                        {
                          this.state.pageNumber === 6 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day5Correct} className="liver-image pr-0 pr-lg-5" alt="Day 6 Correct" />
                        }
                        {
                          this.state.pageNumber === 6 && this.state.medicine === 2 && <img src={Day5Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 6 Wrong" />
                        }
                        {
                          this.state.pageNumber === 7 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day6Correct} className="liver-image pr-0 pr-lg-5" alt="Day 7 Correct" />
                        }
                        {
                          this.state.pageNumber === 7 && this.state.medicine === 2 && <img src={Day6Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 7 Wrong" />
                        }
                        {
                          this.state.pageNumber === 8 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day7Correct} className="liver-image pr-0 pr-lg-5" alt="Day 8 Correct" />
                        }
                        {
                          this.state.pageNumber === 8 && this.state.medicine === 2 && <img src={Day7Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 8 Wrong" />
                        }
                        {
                          this.state.pageNumber === 9 && (this.state.medicine === 0 || this.state.medicine === 1) && <img src={Day8Correct} className="liver-image pr-0 pr-lg-5" alt="Day 8 Correct" />
                        }
                        {
                          this.state.pageNumber === 9 && this.state.medicine === 2 && <img src={Day8Wrong} className="liver-image pr-0 pr-lg-5" alt="Day 8 Wrong" />
                        }
                      </div>


                      <div className="col-md-9">
                        <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
                          <div className="day-box">
                            <p className="day-text">Sun</p>
                            {
                              this.state.pageNumber >= 2 && (this.state.sunMedicine === 0 || this.state.sunMedicine === 1) && <img src={Check} className="day-tick" alt="Check" />
                            }
                            {
                              this.state.pageNumber >= 2 && this.state.sunMedicine === 2 && <img src={Wrong} className="day-tick" alt="Wrong" />
                            }
                          </div>
                          <div className="day-box">
                            <p className="day-text">Mon</p>
                            {
                              this.state.pageNumber >= 3 && (this.state.monMedicine === 0 || this.state.monMedicine === 1) && <img src={Check} className="day-tick" alt="Check" />
                            }
                            {
                              this.state.pageNumber >= 3 && this.state.monMedicine === 2 && <img src={Wrong} className="day-tick" alt="Wrong" />
                            }
                          </div>
                          <div className="day-box">
                            <p className="day-text">Tue</p>
                            {
                              this.state.pageNumber >= 4 && (this.state.tueMedicine === 0 || this.state.tueMedicine === 1) && <img src={Check} className="day-tick" alt="Check" />
                            }
                            {
                              this.state.pageNumber >= 4 && this.state.tueMedicine === 2 && <img src={Wrong} className="day-tick" alt="Wrong" />
                            }
                          </div>
                          <div className="day-box">
                            <p className="day-text">Wed</p>
                            {
                              this.state.pageNumber >= 5 && (this.state.wedMedicine === 0 || this.state.wedMedicine === 1) && <img src={Check} className="day-tick" alt="Check" />
                            }
                            {
                              this.state.pageNumber >= 5 && this.state.wedMedicine === 2 && <img src={Wrong} className="day-tick" alt="Wrong" />
                            }
                          </div>
                          <div className="day-box">
                            <p className="day-text">Thu</p>
                            {
                              this.state.pageNumber >= 6 && (this.state.thuMedicine === 0 || this.state.thuMedicine === 1) && <img src={Check} className="day-tick" alt="Check" />
                            }
                            {
                              this.state.pageNumber >= 6 && this.state.thuMedicine === 2 && <img src={Wrong} className="day-tick" alt="Wrong" />
                            }
                          </div>
                          <div className="day-box">
                            <p className="day-text">Fri</p>
                            {
                              this.state.pageNumber >= 7 && (this.state.friMedicine === 0 || this.state.friMedicine === 1) && <img src={Check} className="day-tick" alt="Check" />
                            }
                            {
                              this.state.pageNumber >= 7 && this.state.friMedicine === 2 && <img src={Wrong} className="day-tick" alt="Wrong" />
                            }
                          </div>
                          <div className="day-box">
                            <p className="day-text">Sat</p>
                            {
                              this.state.pageNumber >= 8 && (this.state.satMedicine === 0 || this.state.satMedicine === 1) && <img src={Check} className="day-tick" alt="Check" />
                            }
                            {
                              this.state.pageNumber >= 8 && this.state.satMedicine === 2 && <img src={Wrong} className="day-tick" alt="Wrong" />
                            }
                          </div>
                          <div className="day-box">
                            <p className="day-text">Sun</p>
                            {
                              this.state.pageNumber >= 9 && (this.state.sun2Medicine === 0 || this.state.sun2Medicine === 1) && <img src={Check} className="day-tick" alt="Check" />
                            }
                            {
                              this.state.pageNumber >= 9 && this.state.sun2Medicine === 2 && <img src={Wrong} className="day-tick" alt="Wrong" />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="text-primary-theme font-weight-bold text-center m-0">Give the man his medicine by dragging the tablet over his mouth</h3>
                  </div>
                  <div className="section-footer">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <button onClick={() => this.handleRedirect("/treatment/how_to_take_your_tablets")} className="btn-sound">
                        <img src={ArrowBack} alt="Arrow Back" />
                      </button>
                      <button onClick={() => this.handleRedirect("/chapter")} className="btn-sound">
                        <h3 className="text-primary-theme m-0">Select Chapter</h3>
                      </button>
                      <button onClick={() => this.handleRedirect("/treatment/treatment_importance")} className="btn-sound">
                        <img src={ArrowNext} alt="Arrow Next" />
                      </button>
                    </div>
                  </div>
                  {/* {
                    this.state.pageNumber === 9 &&
                    // <div className="section-footer">
                    //   <div className="d-flex align-items-center justify-content-between w-100">
                    <Link to="/treatment/treatment_importance" style={{ textAlign: "right" }}>
                      <img src={ArrowNext} alt="Arrow Next" />
                    </Link>
                    //   </div>
                    // </div>
                  } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("chapter mapStateToProps")
  return {
    tokenData: state.token,
    chapterData: state.chapter
  }
}

const mapDispatchToProps = (dispatch) => {
  //console.log("chapter mapDispatchToProps")
  return {
    fetchChapter: (languageid, authToken) => {
      //console.log("chapter mapDispatchToProps 2", authToken)
      dispatch(fetchChapter(languageid, authToken))
    }
  }
}

const GameDay1Form = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GameDay1));

export default GameDay1Form;