import { GET_LANGUAGES, SHOW_LOADER, HIDE_LOADER } from "../constants/types"

const initialState = {
    loading: false,
    languages: [],
    error: ''
}

const languageReducer = (state = initialState, action) => {

    switch (action.type) {

        case GET_LANGUAGES:
            //console.log("fetchLanguage reducer")
            return {
                ...state,
                languages: { ...action.payload }
            }
        case SHOW_LOADER:
            return {
                ...state,
                loading: true
            }

        case HIDE_LOADER:
            return {
                ...state,
                loading: false
            }

        default: return state
    }
}

export default languageReducer