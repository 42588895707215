import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import SoundOn from "../../../assets/images/sound-on.png";
import ArrowBack from "../../../assets/images/arrow-back.svg";
import ArrowNext from "../../../assets/images/arrow-next.svg";
import { connect } from 'react-redux';
import Pause from "../../../assets/images/pause.png";
import { fetchChapter, showLoader, hideLoader } from "../../../redux"
import Loader from "../../loader/loader";

class OtherWaysToGetHepB extends PureComponent {

  constructor(props) {
    super(props)

    this.popupRef = React.createRef();
    this.audioRef = React.createRef();

    const locationState = this.props.location.state || {}
    const { id = 1 } = locationState

    this.state = {
      audio: null,
      isPlaying: false,
      chapter: {},
      subChapter: {},
      audios: [],
      divId: 1 || this.props.location.state.divId,
      id: id,
      currentAudio: '',
      loading: false,
      counter: 0
    }

    this.props.history.replace(this.props.location.pathname, this.state);

  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    //console.log("componentDidUpdate")

    if(this.props.loading === false && this.state.counter === 0) {

      this.setState(prevStates => ({
        ...prevStates,
        counter: prevStates.counter + 1,
      }));


      if (this.state.divId === 1) {

      

        this.state.audios[4].audio.pause();
  
        let promise = this.state.audios[0].audio.play();
  
        if (promise !== undefined) {
          promise.then(_ => {
  
            
              this.state.audios[0].audio.play();
            
  
            let audios = [...this.state.audios];
            audios[0] = { ...this.state.audios[0], 'playing': true };
            //this.setState({ audios });
  
            let subChapter = { ...this.state.subChapter };
            subChapter.data[0] = { ...this.state.subChapter.data[0], 'playing': true };
            //this.setState({ subChapter });
  
            this.setState(prevState => ({
              ...prevState,
              audios: audios,
              subChapter: subChapter
            }));
  
          }).catch(error => {
  
            let audios = [...this.state.audios];
            audios[0] = { ...this.state.audios[0], 'playing': false };
            //this.setState({ audios });
  
            let subChapter = { ...this.state.subChapter };
            subChapter.data[0] = { ...this.state.subChapter.data[0], 'playing': false };
            //this.setState({ subChapter });
  
            this.setState(prevState => ({
              ...prevState,
              audios: audios,
              subChapter: subChapter
            }));
  
          });
        }
  
        let audios = [...this.state.audios];
        audios[4] = { ...this.state.audios[4], 'playing': false };
        //this.setState({ audios });
  
        let subChapter = { ...this.state.subChapter };
        subChapter.data[4] = { ...this.state.subChapter.data[4], 'playing': false };
        //this.setState({ subChapter });
  
        this.setState(prevState => ({
          ...prevState,
          audios: audios,
          subChapter: subChapter,
          currentAudio: this.state.audios[0].fileName
        }));
  
      } else {
  
        this.state.audios[0].audio.pause();
  
        let promise = this.state.audios[4].audio.play();
  
        if (promise !== undefined) {
          promise.then(_ => {
  
            this.state.audios[4].audio.play();
  
            let audios = [...this.state.audios];
            audios[4] = { ...this.state.audios[4], 'playing': true };
            //this.setState({ audios });
  
            let subChapter = { ...this.state.subChapter };
            subChapter.data[4] = { ...this.state.subChapter.data[4], 'playing': true };
            //this.setState({ subChapter });
  
            this.setState(prevState => ({
              ...prevState,
              audios: audios,
              subChapter: subChapter
            }));
  
          }).catch(error => {
  
            let audios = [...this.state.audios];
            audios[4] = { ...this.state.audios[4], 'playing': false };
            //this.setState({ audios });
  
            let subChapter = { ...this.state.subChapter };
            subChapter.data[4] = { ...this.state.subChapter.data[4], 'playing': false };
            //this.setState({ subChapter });
  
            this.setState(prevState => ({
              ...prevState,
              audios: audios,
              subChapter: subChapter
            }));
  
          });
        }
  
  
  
        let audios = [...this.state.audios];
        audios[0] = { ...this.state.audios[0], 'playing': false };
        //this.setState({ audios });
  
        let subChapter = { ...this.state.subChapter };
        subChapter.data[0] = { ...this.state.subChapter.data[0], 'playing': false };
        //this.setState({ subChapter });
  
        this.setState(prevState => ({
          ...prevState,
          audios: audios,
          subChapter: subChapter,
          currentAudio: this.state.audios[4].fileName
        }));
  
      }


    }

    if (this.props.location !== prevProps.location) {
      this.setState(prevStates => ({
        ...prevStates,
        divId: this.props.location.state.divId,
        id: this.props.location.state.id
      }));
    }

    if (this.state.divId === 1) {

      for (let i = 0; i < 4; i++) {

        this.state.audios[i].audio.addEventListener('ended', () => {

          let subChapter = { ...this.state.subChapter };
          subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
          //this.setState({ subChapter });
          this.setState(prevState => ({
            ...prevState,
            subChapter: subChapter
          }));

          if (i < this.state.audios.length - 2) {

            this.state.audios[i + 1].audio.currentTime = 0
            this.state.audios[i + 1].audio.play();

            let audios = [...this.state.audios];
            audios[i + 1] = { ...this.state.audios[i + 1], 'playing': true };
            //this.setState({ audios });

            let subChapter = { ...this.state.subChapter };
            subChapter.data[i + 1] = { ...this.state.subChapter.data[i + 1], 'playing': true };
            //this.setState({ subChapter });

            this.setState(prevState => ({
              ...prevState,
              audios: audios,
              subChapter: subChapter,
              currentAudio: this.state.audios[i + 1].fileName
            }));
          }
        });
      }
    } else {

      for (let i = 4; i < this.state.audios.length; i++) {

        this.state.audios[i].audio.addEventListener('ended', () => {

          let subChapter = { ...this.state.subChapter };
          subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
          this.setState(prevState => ({
            ...prevState,
            subChapter: subChapter
          }));

          if (i < this.state.audios.length - 1) {

            this.state.audios[i + 1].audio.currentTime = 0
            this.state.audios[i + 1].audio.play();

            let audios = [...this.state.audios];
            audios[i + 1] = { ...this.state.audios[i + 1], 'playing': true };
            //this.setState({ audios });

            let subChapter = { ...this.state.subChapter };
            subChapter.data[i + 1] = { ...this.state.subChapter.data[i + 1], 'playing': true };
            //this.setState({ subChapter });

            this.setState(prevState => ({
              ...prevState,
              audios: audios,
              subChapter: subChapter,
              currentAudio: this.state.audios[i + 1].fileName
            }));

          }
        });

      }

    }

  }

  handleRedirect = (pathName) => {
    
    for (let i = 0; i < this.state.audios.length; i++) {
      let audio = this.state.audios[i].audio
      audio.pause();
      let audios = [...this.state.audios];
      audios[i] = { ...this.state.audios[i], 'playing': false };
      //this.setState({ audios });

      let subChapter = { ...this.state.subChapter };
      subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
      //this.setState({ subChapter });

      this.setState(prevState => ({
        ...prevState,
        audios: audios,
        subChapter: subChapter
      }));

    }

    this.props.history.push({
      pathname: pathName,
      state: {
        id: this.state.id
      },
    });
  }

  playPause = (file) => {

    //console.log(this.state)
    for (let i = 0; i < this.state.audios.length; i++) {
      if (this.state.audios[i].playing === true) {

        let audio = this.state.audios[i].audio
        audio.pause();
        let audios = [...this.state.audios];
        audios[i] = { ...this.state.audios[i], 'playing': false };
        //this.setState({ audios });

        let subChapter = { ...this.state.subChapter };
        subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
        //this.setState({ subChapter });

        this.setState(prevState => ({
          ...prevState,
          audios: audios,
          subChapter: subChapter
        }));

      }
    }

    for (let i = 0; i < this.state.audios.length; i++) {
      // console.log(this.state.audios[i].title)
      if (this.state.audios[i].fileName === file[0]) {

        let audio = this.state.audios[i].audio
        let isPlaying
        if (this.state.currentAudio === this.state.audios[i].fileName) {
          isPlaying = this.state.audios[i].playing;
        } else {
          isPlaying = this.state.subChapter.data[i].playing;
          audio.currentTime = 0
        }
        if (isPlaying) {
          audio.pause();
          // console.log("audio paused.. index is", i)
          let audios = [...this.state.audios];
          audios[i] = { ...this.state.audios[i], 'playing': false };
          //this.setState({ audios });

          let subChapter = { ...this.state.subChapter };
          subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
          //this.setState({ subChapter });

          this.setState(prevState => ({
            ...prevState,
            audios: audios,
            subChapter: subChapter
          }));

        } else {
          // console.log("audio played.. index is", i)
          audio.play();
          let audios = [...this.state.audios];
          audios[i] = { ...this.state.audios[i], 'playing': true };
          //this.setState({ audios });

          let subChapter = { ...this.state.subChapter };
          subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': true };
          //this.setState({ subChapter });

          this.setState(prevState => ({
            ...prevState,
            audios: audios,
            subChapter: subChapter,
            currentAudio: this.state.audios[i].fileName
          }));

        }

        break;

      }
    }

  };

  componentDidMount() {

    this.setState(prevState => ({
      ...prevState,
      loading: this.props.loading
    }));

    //console.log("componentDidMount")
    this.state.subChapter.data.map(detail => {

      const obj = { 'audio': new Audio(detail.audio[0]), 'fileName': detail.audio[0], 'title': detail.title, 'playing': false };
      
      this.state.audios.push(obj)

    })

  //   setTimeout(() => {

  //   if (this.state.divId === 1) {

      

  //     this.state.audios[3].audio.pause();

  //     let promise = this.state.audios[0].audio.play();

  //     if (promise !== undefined) {
  //       promise.then(_ => {

          
  //           this.state.audios[0].audio.play();
          

  //         let audios = [...this.state.audios];
  //         audios[0] = { ...this.state.audios[0], 'playing': true };
  //         //this.setState({ audios });

  //         let subChapter = { ...this.state.subChapter };
  //         subChapter.data[0] = { ...this.state.subChapter.data[0], 'playing': true };
  //         //this.setState({ subChapter });

  //         this.setState(prevState => ({
  //           ...prevState,
  //           audios: audios,
  //           subChapter: subChapter
  //         }));

  //       }).catch(error => {

  //         let audios = [...this.state.audios];
  //         audios[0] = { ...this.state.audios[0], 'playing': false };
  //         //this.setState({ audios });

  //         let subChapter = { ...this.state.subChapter };
  //         subChapter.data[0] = { ...this.state.subChapter.data[0], 'playing': false };
  //         //this.setState({ subChapter });

  //         this.setState(prevState => ({
  //           ...prevState,
  //           audios: audios,
  //           subChapter: subChapter
  //         }));

  //       });
  //     }

  //     let audios = [...this.state.audios];
  //     audios[3] = { ...this.state.audios[3], 'playing': false };
  //     //this.setState({ audios });

  //     let subChapter = { ...this.state.subChapter };
  //     subChapter.data[3] = { ...this.state.subChapter.data[3], 'playing': false };
  //     //this.setState({ subChapter });

  //     this.setState(prevState => ({
  //       ...prevState,
  //       audios: audios,
  //       subChapter: subChapter,
  //       currentAudio: this.state.audios[0].fileName
  //     }));

  //   } else {

  //     this.state.audios[0].audio.pause();

  //     let promise = this.state.audios[3].audio.play();

  //     if (promise !== undefined) {
  //       promise.then(_ => {

  //         this.state.audios[3].audio.play();

  //         let audios = [...this.state.audios];
  //         audios[3] = { ...this.state.audios[3], 'playing': true };
  //         //this.setState({ audios });

  //         let subChapter = { ...this.state.subChapter };
  //         subChapter.data[3] = { ...this.state.subChapter.data[3], 'playing': true };
  //         //this.setState({ subChapter });

  //         this.setState(prevState => ({
  //           ...prevState,
  //           audios: audios,
  //           subChapter: subChapter
  //         }));

  //       }).catch(error => {

  //         let audios = [...this.state.audios];
  //         audios[3] = { ...this.state.audios[3], 'playing': false };
  //         //this.setState({ audios });

  //         let subChapter = { ...this.state.subChapter };
  //         subChapter.data[3] = { ...this.state.subChapter.data[3], 'playing': false };
  //         //this.setState({ subChapter });

  //         this.setState(prevState => ({
  //           ...prevState,
  //           audios: audios,
  //           subChapter: subChapter
  //         }));

  //       });
  //     }



  //     let audios = [...this.state.audios];
  //     audios[0] = { ...this.state.audios[0], 'playing': false };
  //     //this.setState({ audios });

  //     let subChapter = { ...this.state.subChapter };
  //     subChapter.data[0] = { ...this.state.subChapter.data[0], 'playing': false };
  //     //this.setState({ subChapter });

  //     this.setState(prevState => ({
  //       ...prevState,
  //       audios: audios,
  //       subChapter: subChapter,
  //       currentAudio: this.state.audios[3].fileName
  //     }));

  //   }

  // }, 2000);

    //window.addEventListener("focus", this.onFocus)
    window.addEventListener('blur', this.onBlur);

  }

  onFocus = () => {
    if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].video && this.state.subChapter.data[0].video[0]) {
      this.vidRef.current.play();
    } else if (this.state.subChapter.data && this.state.subChapter.data[0] && this.state.subChapter.data[0].audio && this.state.subChapter.data[0].audio[0]) {
      this.audioRef.current.play();
    }
    this.setState(prevState => ({
      ...prevState,
      isPlaying: true
    }));
  }

  onBlur = () => {

    if (this.state.divId === 1) {

      for (let i = 0; i < 4; i++) {

        if (this.state.audios[i].playing === true) {

          let audio = this.state.audios[i].audio
          audio.pause();
          let audios = [...this.state.audios];
          audios[i] = { ...this.state.audios[i], 'playing': false };
  
          let subChapter = { ...this.state.subChapter };
          subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
  
          this.setState(prevState => ({
            ...prevState,
            audios: audios,
            subChapter: subChapter
          }));
        }

      }

    } else {

      for (let i = 4; i < this.state.audios.length; i++) {

        if (this.state.audios[i].playing === true) {

          let audio = this.state.audios[i].audio
          audio.pause();
          let audios = [...this.state.audios];
          audios[i] = { ...this.state.audios[i], 'playing': false };
  
          let subChapter = { ...this.state.subChapter };
          subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
  
          this.setState(prevState => ({
            ...prevState,
            audios: audios,
            subChapter: subChapter
          }));
        }
      
      }

    }

  }

  componentWillUnmount() {
    this.state.audios.map((audio, index) => {
      audio.audio.pause();
      audio.audio.removeEventListener('ended', () => {
        let subChapter = { ...this.state.subChapter };
        subChapter.data[index] = { ...this.state.subChapter.data[index], 'playing': false };
        this.setState(prevState => ({
          ...prevState,
          subChapter: subChapter
        }));
      });
    })
    //window.removeEventListener("focus", this.onFocus)
    window.removeEventListener('blur', this.onBlur);
  }

  componentWillMount() {

    this.props.showLoader()

    this.setState(prevState => ({
      ...prevState,
      loading: this.props.loading
    }));

    //console.log("Introduction data componentWillMount", this.props)
    this.props.fetchChapter(this.state.id, this.props.tokenData.authToken.token)
    var array = this.props.match.path.split("/");
    //console.log(array[array.length - 1])
    let chapterVar = this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
    //console.log(chapterVar);
    this.setState(prevState => ({
      ...prevState,
      chapter: chapterVar,
      subChapter: chapterVar.sub_chapters.find(subchapter => subchapter.identifier === array[array.length - 1])
    }));
  }

  showPopup = (showHide) => {
    this.popupRef.current.style.display = showHide

    if (showHide === "none") {
      for (let i = 4; i < this.state.audios.length; i++) {
        this.state.audios[i].audio.pause();
        let audios = [...this.state.audios];
        audios[i] = { ...this.state.audios[i], 'playing': false };
        //this.setState({ audios });

        let subChapter = { ...this.state.subChapter };
        subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
        //this.setState({ subChapter });

        this.setState(prevState => ({
          ...prevState,
          audios: audios,
          subChapter: subChapter
        }));

      }
      let audio = this.state.audios[0].audio
      audio.currentTime = 0
      audio.play();
      let audios = [...this.state.audios];
      audios[0] = { ...this.state.audios[0], 'playing': true };
      //this.setState({ audios });

      let subChapter = { ...this.state.subChapter };
      subChapter.data[0] = { ...this.state.subChapter.data[0], 'playing': true };
      //this.setState({ subChapter });

      this.setState(prevState => ({
        ...prevState,
        audios: audios,
        subChapter: subChapter,
        currentAudio: this.state.audios[0].fileName
      }));

    }

    this.props.history.push({
      pathname: '/story/other_ways_to_get_hep_b',
      state: {
        divId: 1,
        id: this.state.id
      },
    });
  }

  handleRedirectOnSkip = () => {

    for (let i = 0; i < this.state.audios.length; i++) {
      let audio = this.state.audios[i].audio
      audio.pause();
      let audios = [...this.state.audios];
      audios[i] = { ...this.state.audios[i], 'playing': false };
      //this.setState({ audios });

      let subChapter = { ...this.state.subChapter };
      subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
      //this.setState({ subChapter });

      this.setState(prevState => ({
        ...prevState,
        audios: audios,
        subChapter: subChapter
      }));

    }

    //this.props.history.push("/what_your_liver_does/liver_function");
    this.props.history.push({
      pathname: '/what_your_liver_does/liver_function',
      state: {
        id: this.state.id
      },
    });
  }

  handleRedirectToMainPage = (divId) => {
    this.popupRef.current.style.display = "none"
    for (let i = 0; i < 4; i++) {
      this.state.audios[i].audio.pause();
      let audios = [...this.state.audios];
      audios[i] = { ...this.state.audios[i], 'playing': false };
      //this.setState({ audios });

      let subChapter = { ...this.state.subChapter };
      subChapter.data[i] = { ...this.state.subChapter.data[i], 'playing': false };
      //this.setState({ subChapter });

      this.setState(prevState => ({
        ...prevState,
        audios: audios,
        subChapter: subChapter
      }));

    }

    let audio = this.state.audios[4].audio
    audio.currentTime = 0
    audio.play();

    let audios = [...this.state.audios];
    audios[4] = { ...this.state.audios[4], 'playing': true };
    //this.setState({ audios });

    let subChapter = { ...this.state.subChapter };
    subChapter.data[4] = { ...this.state.subChapter.data[4], 'playing': true };
    //this.setState({ subChapter });

    this.setState(prevState => ({
      ...prevState,
      audios: audios,
      subChapter: subChapter,
      currentAudio: this.state.audios[4].fileName
    }));

    this.props.history.push({
      pathname: '/story/other_ways_to_get_hep_b',
      state: {
        divId: divId,
        id: this.state.id
      },
    });
    // this.props.history.push(`/chapter/${id}`);
  };

  render() { 

    return (
      this.props.loading
        ?
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-body flex-fill">
                  <Loader />
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        :
        <React.Fragment>
          <div className="wrapper">
            <div className="main-container">
              <div className="container">
                <div className="content">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-header">
                    <h1 className="m-0">
                      {
                        this.state.id === 1 ?
                          <><span className="font-size-45 font-weight-bold text-primary-theme">{this.state.chapter.chapter_name} </span>
                            {this.state.subChapter.sub_chapter_name}</>
                          :
                          <span className="font-size-45 font-weight-bold text-primary-theme">{this.state.subChapter.sub_chapter_name}</span>
                      }
                    </h1>
                  </div>
                  <div className="section-body flex-fill">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center mt-0 mt-lg-4 other-ways-to-get-hepb">


                      {

                        this.state.subChapter.data.map((detail, index) => {
                          //console.log("divId", this.state.divId)
                          let condition = this.state.divId === 1 ? index <= 3 : index >= 4
                          //console.log("index", index)
                          //console.log("condition", condition)
                          return (
                            condition && //<div key={index} className="col mb-4">
                              <div key={index} className={(this.state.divId === 1 ? 'col mb-4' : 'col-lg-8 mb-4')}>
                              <div className="section-image d-flex align-items-center mb-3">
                                <img src={detail.image} alt={detail.title} className="mr-3" height="110" width="110" />

                                {/* {
                                  detail.image && detail.image[0] && <img ref={this.imgRef} src={detail.image[0]} alt={detail.title} className="mr-3" height="110" width="110" />
                                } */}

                                {/* <Link to="">
                                <img src={detail.audio} alt="Sound" />
                              </Link> */}

                                <button onClick={() => this.playPause(detail.audio)} className="btn-sound">
                                  {
                                    detail.playing
                                      ? <img src={Pause} alt="Pause" />
                                      : <img src={SoundOn} alt="Sound" />
                                  }
                                </button>
                              </div>
                              <div className="text-left">
                                <h4 className="h6">{detail.title}</h4>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                                {
                                  detail.content.split("\n").map((item, idx) => {
                                    return (
                                      <span key={idx}>
                                        {item}
                                        <br />
                                      </span>
                                    )
                                  })
                                }
                                {/* {ReactHtmlParser(detail.content)} */}
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                    <br />
                        <a href="https://bmcpublichealth.biomedcentral.com/articles/10.1186/1471-2458-14-1233" target="_blank" className="link" >Davies et al. (2014),</a>
                        <a href="https://www.researchgate.net/publication/278042538_Development_of_a_Culturally_Appropriate_Bilingual_Electronic_App_About_Hepatitis_B_for_Indigenous_Australians_Towards_Shared_Understandings" target="_blank" className="link"> Davies et al. (2015)</a>
                  </div>
                  <div className="section-footer">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      {this.state.divId === 1 ?
                        <button onClick={() => this.handleRedirect("/story/fishing")} className="btn-sound">
                          <img src={ArrowBack} alt="Arrow Back" />
                        </button> :
                        <button type="button" onClick={() => this.showPopup("none")} className="btn-sound">
                          <img src={ArrowBack} alt="Arrow Back" />
                        </button>}
                      <button onClick={() => this.handleRedirect("/chapter")} className="btn-sound">
                        <h3 className="text-primary-theme m-0">Select Chapter</h3>
                      </button>
                      {this.state.divId === 2 ?
                        <button onClick={() => this.handleRedirect("/what_your_liver_does/liver_function")} className="btn-sound">
                          <img src={ArrowNext} alt="Arrow Next" />
                        </button> :
                        <button onClick={() => this.showPopup("inline")} className="btn-sound">
                          <img src={ArrowNext} alt="Arrow Next" />
                        </button>}
                    </div>
                  </div>
                </div>
                <div ref={this.popupRef} style={{ display: "none" }} className="fade modal show">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                      <div className="modal-body text-center p-4">
                        {/* <h5 className="h4">Next topics are related to <br /> women's health.</h5>
                        <h4 className="text-primary-theme font-weight-900 m-0">Are you sure you want to read?</h4> */}
                        {
                          this.state.subChapter.warning_message.split("\n").map((item, idx) => {
                            return (
                              idx < 2 
                              ?
                              <h5 className="h4" key={idx}>
                                {item}
                                <br />
                              </h5>
                              :
                              <h4 className="text-primary-theme font-weight-900 m-0" key={idx}>
                                {item}
                                <br />
                              </h4>
                            )
                          })
                        }
                      </div>
                      <div className="modal-footer p-0 border-0">
                        <button type="button" onClick={() => this.handleRedirectOnSkip()} className="btn btn-secondary-theme">{this.state.subChapter.skip_label}</button>
                        <button type="button" onClick={() => this.handleRedirectToMainPage(2)} className="btn btn-primary-theme">{this.state.subChapter.play_label}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("chapter mapStateToProps")
  return {
    tokenData: state.token,
    chapterData: state.chapter,
    loading: state.chapter.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  //console.log("chapter mapDispatchToProps")
  return {
    fetchChapter: (languageid, authToken) => {
      //console.log("chapter mapDispatchToProps 2", authToken)
      dispatch(fetchChapter(languageid, authToken))
    },
    showLoader: () => {
      dispatch(showLoader())
    },
    hideLoader: () => {
      dispatch(hideLoader())
    }
  }
}

const OtherWaysToGetHepBForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherWaysToGetHepB));

export default OtherWaysToGetHepBForm;