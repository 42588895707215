import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { fetchChapter } from "../../../redux"
import ArrowNext from "../../../assets/images/arrow-next.svg";
import ArrowBack from "../../../assets/images/arrow-back.svg";

class GameLevel extends PureComponent {

  constructor(props) {
    super(props)

    const locationState = this.props.location.state || {}
    const { id = 1, pageName = "how_to_take_your_tablets" } = locationState
  
    this.state = {
      id: id,
      pageName: pageName,
      chapter: {},
      subChapter: {}
    }

    this.props.history.replace(this.props.location.pathname, this.state);

  }

  componentWillMount() {
    //console.log("HowToTakeYourTablets data componentWillMount", this.props)
    this.props.fetchChapter(this.state.id, this.props.tokenData.authToken.token)
    var array = this.props.match.path.split("/");
    //console.log(array[array.length - 1])
    let chapterVar = this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
    //console.log(chapterVar);
    this.setState(prevState => ({
      ...prevState,
      chapter: chapterVar,
      subChapter: chapterVar.sub_chapters.find(subchapter => subchapter.identifier === this.state.pageName)
    }));
  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    //console.log("componentDidUpdate")
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (this.props.location !== prevProps.location) {
      this.setState(prevStates => ({
        ...prevStates,
        id: this.props.location.state.id,
        pageName: this.props.location.state.pageName
      }));
      // this.setState(this.props.location.state);
    }

    if (this.props.chapterData !== prevProps.chapterData) {
      var array = this.props.match.path.split("/");
      let chapterVar = this.props.chapterData.chapters.list.find(chapter => chapter.identifier === array[1]);
      this.setState(prevState => ({
        ...prevState,
        chapter: chapterVar,
        subChapter: chapterVar.sub_chapters.find(subchapter => subchapter.identifier === this.state.pageName)
      }));
    }

  }
  

  handleRedirectToGameDay1 = (levelName) => {
    this.props.history.push({
      pathname: '/treatment/game_day_1',
      state: {
        gameLevel: levelName,
        id: this.state.id,
        pageName: this.state.pageName
      },
    });
  };

  handleRedirect = (pathName) => {
    //this.props.history.push(pathName);
    this.props.history.push({
      pathname: pathName,
      state: {
        id: this.state.id,
        pageName: this.state.pageName
      },
    });
  }

  handleRedirectToGameSuccess = () => {
    this.props.history.push("/treatment/game_success");
  };

  handleRedirectToGameFailure = () => {
    this.props.history.push("/treatment/game_failure");
  };

  handleRedirectToBack = () => {
    //this.props.history.push("/treatment/how_to_take_your_tablets");
    this.props.history.push({
      pathname: '/treatment/how_to_take_your_tablets',
      state: {
        id: this.state.id
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <div className="main-container">
            <div className="container">
              <div className="content">
                <div className="section d-flex flex-column flex-fill">
                  <div className="section-header">
                    {/* <h1 className="m-0"><span className="font-size-45 font-weight-bold text-primary-theme">Treatment</span> How to take your tablets</h1> */}
                    <h1 className="m-0">
                      {
                        this.state.id === 1 ?
                        <><span className="font-size-45 font-weight-bold text-primary-theme">{this.state.chapter.chapter_name} </span> 
                        {this.state.subChapter.sub_chapter_name}</>
                        :
                        <span className="font-size-45 font-weight-bold text-primary-theme">{this.state.subChapter.sub_chapter_name}</span> 
                      }
                    </h1>
                  </div>
                  <div className="section-body flex-fill">
                    <div className="game-level-container d-flex flex-column mt-1 mt-lg-4 mb-2 mb-lg-4">
                      <button type="button" onClick={() => this.handleRedirectToGameDay1("Hard")} className="btn btn-lg btn-success-theme mb-4">Hard</button>
                      <button type="button" onClick={() => this.handleRedirectToGameDay1("Medium")} className="btn btn-lg btn-success-theme mb-4">Medium</button>
                      <button type="button" onClick={() => this.handleRedirectToGameDay1("Easy")} className="btn btn-lg btn-success-theme mb-4">Easy</button>
                    </div>
                    <h3 className="text-primary-theme font-weight-bold text-center m-0">Give the man his medicine by dragging the tablet over his mouth</h3>
                  </div>
                  <div className="section-footer">
                      {/* <button type="button" onClick={this.handleRedirectToBack} className="btn btn-lg btn-primary-theme back-btn">
                        Back
                      </button> */}
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <button onClick={() => this.handleRedirect("/treatment/how_to_take_your_tablets")} className="btn-sound">
                          <img src={ArrowBack} alt="Arrow Back" />
                        </button>
                        <button onClick={() => this.handleRedirect("/chapter")} className="btn-sound">
                          <h3 className="text-primary-theme m-0">Select Chapter</h3>
                        </button>
                        <button onClick={() => this.handleRedirect("/treatment/treatment_importance")} className="btn-sound">
                          <img src={ArrowNext} alt="Arrow Next" />
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("chapter mapStateToProps")
  return {
    tokenData: state.token,
    chapterData: state.chapter
  }
}

const mapDispatchToProps = (dispatch) => {
  //console.log("chapter mapDispatchToProps")
  return {
    fetchChapter: (languageid, authToken) => {
      //console.log("chapter mapDispatchToProps 2", authToken)
      dispatch(fetchChapter(languageid, authToken))
    }
  }
}

const GameLevelForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GameLevel));

export default GameLevelForm;