import { GET_CHAPTERS, SHOW_LOADER, HIDE_LOADER, REMOVE_DATA, API_BASE_URL } from "../constants/types"
import axios from "axios"
import FormData from 'form-data'

const getChapters = (chapters) => {
    return {
        type: GET_CHAPTERS,
        payload: chapters
    }
}

const displayLoader = () => {
    return {
        type: SHOW_LOADER
    }
}

export const showLoader = () => {
    return (dispatch) => {
        dispatch(displayLoader())
    }
}

const removeLoader = () => {
    return {
        type: HIDE_LOADER
    }
}

export const hideLoader = () => {
    return (dispatch) => {
        dispatch(removeLoader())
    }
}

const removeData = (empty) => {
    return {
        type: REMOVE_DATA,
        payload: empty
    }
}

export const removeChapterData = () => {

    return (dispatch) => {
        dispatch(removeData([]))
    }
}

export const fetchChapter = (languageId, authToken) => {

    //console.log("fetchChapter action", authToken)

    return (dispatch) => {

        var chapterData = new FormData();
        chapterData.append('language_id', languageId);

        axios({
            method: "post",
            url: `${API_BASE_URL}/api/v1/common/get-chapter`,
            data: chapterData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                "Authorization": `Bearer ${authToken}`
            }
        })
            .then(res => {
                dispatch(getChapters(res.data.data))
                dispatch(hideLoader())
                //console.log(res.data)
                //console.log(res.data.data)
                //console.log(res.data.data.list)
            })
            .catch(err => {
                //console.log(err)
            })
    }
}